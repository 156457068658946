import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Typography } from "@mui/material";

import NavDrawer from "../../components/Drawer";

const Integrations = () => {

    return (
        <Box sx={{display:'flex'}}>
            <NavDrawer />
            <Grid container direction={'column'}>
                <Grid item sx={{display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', flexDirection:'column'}}>
                    <Stack direction={'row'} spacing={3} sx={{margin:'20px'}}>
                        <Card elevation={3} sx={{width:'200px', height:'200px', display:'flex', justifyContent:'center', alignItems:'center', '&:hover':{transform: 'scale(1.04)'}, borderRadius:'30px'}}>
                            <CardContent>
                                <img src={'https://hhcrossfit-dev-profilepics.s3.amazonaws.com/GMLOGO.png'} style={{width:'160px', height:'100px'}} />
                            </CardContent>
                        </Card>
                        <Card elevation={3} sx={{width:'200px', height:'200px', display:'flex', justifyContent:'center', alignItems:'center', '&:hover':{transform: 'scale(1.04)'}, borderRadius:'30px'}}>
                            <CardContent>
                                <img src={'https://hhcrossfit-dev-profilepics.s3.amazonaws.com/fitbitlogo.png'} style={{width:'170px', height:'50px'}} />
                            </CardContent>
                        </Card>
                        <Card elevation={3} sx={{width:'200px', height:'200px', display:'flex', justifyContent:'center', alignItems:'center', '&:hover':{transform: 'scale(1.04)'}, borderRadius:'30px'}}>
                            <CardContent>
                            <img src={'https://hhcrossfit-dev-profilepics.s3.amazonaws.com/whoopLogo.jpg'} style={{width:'170px', height:'116px'}} />
                            </CardContent>
                        </Card>
                    </Stack>
                    <Stack direction={'row'} spacing={3}>
                        <Card elevation={3} sx={{width:'200px', height:'200px', display:'flex', justifyContent:'center', alignItems:'center', '&:hover':{transform: 'scale(1.04)'}, borderRadius:'30px'}}>
                            <CardContent>
                                <img src={'https://hhcrossfit-dev-profilepics.s3.amazonaws.com/Garmin-logo.png'} style={{width:'160px', height:'100px'}} />
                            </CardContent>
                        </Card>
                        <Card elevation={3} sx={{width:'200px', height:'200px', display:'flex', justifyContent:'center', alignItems:'center', '&:hover':{transform: 'scale(1.04)'}, borderRadius:'30px'}}>
                            <CardContent>
                                <img src={'https://hhcrossfit-dev-profilepics.s3.amazonaws.com/iwatch.png'} style={{width:'170px', height:'40px'}} />
                            </CardContent>
                        </Card>
                        <Card elevation={3} sx={{width:'200px', height:'200px', display:'flex', justifyContent:'center', alignItems:'center', '&:hover':{transform: 'scale(1.04)'}, borderRadius:'30px'}}>
                            <CardContent>
                            <img src={'https://hhcrossfit-dev-profilepics.s3.amazonaws.com/myfitnesspallogo.png'} style={{width:'170px', height:'116px'}} />
                            </CardContent>
                        </Card>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Integrations;