import React, { useState } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_TEAM_MEMBERS } from "../../utils/queries";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { PieChart, pieArcClasses } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import "../styles.css";


import NavDrawer from "../../components/Drawer";
import Auth from "../../utils/auth";

const thisId = Auth.getId();

const TrainerDashboard = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const theme = useTheme();
    const currentDate = new Date().toLocaleDateString('en-US');

    const columns = [
        {field: 'id', headerName: 'ID', flex:1},
        {field: 'name', headerName: 'Name', flex: 1},
        {field: 'checkIn', headerName: 'Check In Completed', flex: 0.5},
        {field: 'workoutScheduled', headerName: 'Workout Scheduled', flex: 0.5},
        {field: 'workoutCompleted', headerName: 'Workout Completed', flex: 0.5},
    ];

    // State
    const [rows, setRows] = useState([]);

    const handleRowClick = (rowData) => {
        navigate(`/trainer/client/${rowData.id}`)
    };

    const { data, error } = useQuery(GET_TEAM_MEMBERS, {
        variables: { trainerId: thisId },
    });

    const myTeam = data?.getTeamMembers;
    const missingDosUsers = data?.getTeamMembers.filter(user => !user.dailyOneSheets.some(sheet => sheet.forDate === currentDate));
    const completedDosUsers = data?.getTeamMembers.filter(user => user.dailyOneSheets.some(sheet => sheet.forDate === currentDate));
    
    useEffect(() => {
        if (data?.getTeamMembers) {
            const tmp = myTeam.map((client) => {
                return {
                    id: client._id,
                    name: client.fullName,
                    checkIn: client.dailyOneSheets.some(sheet => sheet.forDate === currentDate) ? 'Yes' : 'No',
                    workoutScheduled: client.workouts.some(workout => workout.scheduled === currentDate) ? 'Yes' : 'No',
                    workoutCompleted: client.workouts.some(workout => workout.completedAt === currentDate) ? 'Yes' : 'No',
                }
            });
            setRows(tmp);
        }
    }, [data?.getTeamMembers]);

    return (
        <Box sx={{ display: 'flex' }}>
            <NavDrawer />
            <Grid container direction={'column'} sx={{ display: 'flex' }}>
                <Grid item>
                    <h2 style={{ color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main, textAlign: 'center', fontFamily: 'Montserrat' }}>Trainer Dashboard</h2>
                </Grid>
                <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Stack direction={'row'} width={'100%'} sx={{ display: 'flex', justifyContent: 'center' }} spacing={2}>
                        <Card sx={{ width: '30%', height: '240px', backgroundColor: prefersDarkMode ? 'transparent' : 'white' }}>
                            <CardContent>
                                <Stack sx={{ display: 'flex', alignItems: 'center' }}>
                                    <h4 style={{ color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main, textAlign: 'center' }}>Active Clients</h4>
                                    <Typography variant="h3">{myTeam?.length}</Typography>
                                </Stack>
                            </CardContent>
                            <CardActions sx={{ justifyContent: 'center' }}>
                                <Button onClick={() => navigate(`/trainer/clients`)}>View All Clients</Button>
                            </CardActions>
                        </Card>
                        <Card sx={{ width: '30%', height: '240px', backgroundColor: prefersDarkMode ? 'transparent' : 'white' }}>
                            <CardContent>
                                <Stack>
                                    <h4 style={{ color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main, textAlign: 'center' }}>Check-In Status</h4>
                                    <PieChart
                                        series={[
                                            {
                                                data: [
                                                    { id: 0, value: completedDosUsers?.length, label: 'Completed', color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main },
                                                    { id: 1, value: missingDosUsers?.length, label: 'Waiting', color: prefersDarkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main },
                                                ],
                                            },
                                        ]}
                                        width={200}
                                        height={120}
                                        sx={{
                                            [`& .${pieArcClasses.faded}`]: {
                                                fill: "gray",
                                            },
                                            "--ChartsLegend-itemWidth": "0px",
                                        }}
                                    />
                                </Stack>
                            </CardContent>
                        </Card>
                        <Card sx={{ width: '30%', height: '240px', backgroundColor: prefersDarkMode ? 'transparent' : 'white' }}>
                            <CardContent>
                                <h4 style={{ color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main, textAlign: 'center' }}>Remaining Sessions (Today)</h4>
                            </CardContent>
                        </Card>
                    </Stack>
                </Grid>
                <Grid item sx={{marginTop:'20px'}}>
                    <Stack sx={{display:'flex', alignItems:'center'}}>
                        <DataGrid
                            sx={{width:'90%'}}
                            columns={columns}
                            rows={rows}
                            onRowClick={handleRowClick}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    }
                                }
                            }}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default TrainerDashboard;
