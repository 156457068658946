import * as React from 'react';
import { useState } from 'react';
import useMediaQuery from "@mui/material/useMediaQuery";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { CircularProgress } from '@mui/material';

const LoadingScreen = () => {
    return (
        <Box sx={{display:'flex', justifyContent:'center', marginTop:'300px'}}>
            <Stack sx={{display:'flex', justifyContent:'center'}}>
                <CircularProgress />
                <p>loading</p>
            </Stack>
        </Box>
    );
};

export default LoadingScreen;