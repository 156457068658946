import React, { useState } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useQuery } from "@apollo/client";
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import { QUERY_WOD } from "../../utils/queries";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import DOSDialog from "../Dialogs/DailyOneSheetDialog";
import CheckInDetails from "../Dialogs/CheckInDetails";
import POCalendar from "../../pages/fullcalendar/POC";

<style>
  @import url('https://fonts.googleapis.com/css2?family=Lobster&family=Protest+Revolution&display=swap');
</style>



const TodayTabDetails = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const currentDate = new Date().toLocaleDateString('en-US');

    // State Variables
    const [openDailyOne, setOpenDailyOne] = useState(false);
    const [openCheckInDetails, setOpenCheckInDetails] = useState(false);
    const [userId, setUserId] = useState("");
    const [dailyCheckIn, setDailyCheckIn] = useState();
    const [hasTrainer, setHasTrainer] = useState(false);
    const [thisWorkout, setThisWorkout] = useState();

    useEffect(() => {
        if (props.thisUser) {
            setUserId(props.thisUser?._id);
        }

        if (props.todayCheckIn) {
            setDailyCheckIn(props.todayCheckIn);
        }

        if (props.thisUser?.onTeam) {
            setHasTrainer(true);
        }

        if (props.todayWorkout) {
            setThisWorkout(props.todayWorkout[0]);
        }

    }, []);

    // Refresh Trigger Function
    const refreshParent = () => {
        window.location.reload();
    };

    return (
        <Grid container direction={'column'}>
            <Grid item sx={{display:'flex', justifyContent:'center'}}>
                <h3 style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.secondary.main}}>Welcome, {props.thisUser?.fullName}</h3>
            </Grid>
            <Grid item sx={{display:'flex', justifyContent:'center'}}>
                <Stack>
                    <Card 
                        elevation={3} 
                        sx={{
                            height:'500px', 
                            width:'914px', 
                            marginTop:'10px', 
                            backgroundColor: prefersDarkMode ? darkTheme.palette.background.default : lightTheme.palette.background.default,
                            border: prefersDarkMode ? '1px solid' : 'none',
                            borderColor: prefersDarkMode ? darkTheme.palette.primary.main : 'none',
                            '&:hover': {
                                border: '1px solid',
                                borderColor: prefersDarkMode ? darkTheme.palette.secondary.main : lightTheme.palette.primary.main
                            }
                        }}
                    >
                        <CardContent>
                            <h1 style={{textAlign:'center', color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main,
                                fontFamily:'Protest Revolution'
                            }}>
                                <Trans i18nKey="todayTab.part1"></Trans>
                            </h1>
                            <Stack direction={'row'} sx={{display:'flex', justifyContent:'center'}}>
                                <ul style={{ listStyleType: 'none', padding: 0, color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main }}>
                                    {thisWorkout?.exercises.map((exercise, index) => (
                                        <li key={index}>
                                            {exercise.name}
                                        </li>
                                    ))}
                                </ul>
                            </Stack>
                        </CardContent>
                    </Card>
                    {hasTrainer ? (
                        <>
                            <Stack direction={'row'} spacing={2} sx={{marginTop:'10px'}}>
                                {props.dailyOneComplete === true ? (
                                    <Button onClick={() => setOpenCheckInDetails(true)} variant="outlined">
                                        <Trans i18nKey="todayTab.part8"></Trans>
                                    </Button>
                                ):(
                                    <Button onClick={() => setOpenDailyOne(true)} variant="outlined">
                                        <Trans i18nKey="todayTab.part9"></Trans>
                                    </Button>
                                )}
                            </Stack>
                        </>
                    ):(
                        <>
                        </>
                    )}
                </Stack>
            </Grid>
            {openDailyOne && (
                <DOSDialog 
                    openDailyOne={openDailyOne}
                    setOpenDailyOne={setOpenDailyOne}
                    userId={userId}
                    refreshParent={refreshParent}
                />
            )}
            {openCheckInDetails && (
                <CheckInDetails 
                    openCheckInDetails={openCheckInDetails}
                    setOpenCheckInDetails={setOpenCheckInDetails}
                    dailyCheckIn={dailyCheckIn}
                />
            )}
        </Grid>
    )
};

export default TodayTabDetails;