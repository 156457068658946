import React, { useState } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { QUERY_USER } from "../../utils/queries";
import { GET_TEAM_MEMBERS } from "../../utils/queries";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import NavDrawer from "../../components/Drawer";
import Auth from "../../utils/auth";

// Client Tabs Import
import ClientDetailsTab from "./Tabs/ClientDetailsTab";
import ClientCheckInTab from "./Tabs/ClientCheckInsTab";
import ClientChallengeTab from "./Tabs/ClientChallengeTab";
import ClientWorkoutsTab from "./Tabs/ClientWorkoutsTab";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
};

const ClientProfile = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const theme = useTheme();
    const currentDate = new Date().toLocaleDateString('en-US');
    let { id } = useParams();

    const { data, error } = useQuery(QUERY_USER, {
        variables: { _id: id },
    });

    const thisClient = data?.user;

    // Tabs 
    const [tabValue, setTabValue] = useState(
        window.location.hash !== "" ? Number(window.location.hash.slice(1)) : 0
    );
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        window.location.hash = `#${newValue}`;
    };

    return (
        <Box sx={{display:'flex'}}>
            <NavDrawer />
            <Grid container direction={'column'}>
                <Grid item sx={{display:'flex'}}>
                        <Button
                            sx={{margin:'10px'}}
                            startIcon={<ArrowBackIosIcon />}
                            variant="outlined"
                            onClick={() => navigate(`/trainer/clients`)}
                        >
                            Back to Clients
                        </Button>
                        <h4 style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Viewing Details for {data?.user.fullName}</h4>
                </Grid>
                <Grid item xs>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        variant="fullWidth"
                    >
                        <Tab label="User Details" {...a11yProps(0)} />
                        <Tab label="Check Ins" {...a11yProps(1)} />
                        <Tab label="Challenge" {...a11yProps(2)} />
                        <Tab label="Workouts" {...a11yProps(3)} />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                        <ClientDetailsTab 
                            thisClient={thisClient}
                        />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <ClientCheckInTab 
                            thisClient={thisClient}
                        />
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <ClientChallengeTab 
                            thisClient={thisClient}
                        />
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                        <ClientWorkoutsTab 
                            thisClient={thisClient}
                        />
                    </TabPanel>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ClientProfile;