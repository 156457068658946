import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTheme } from '@emotion/react';
import useMediaQuery from "@mui/material/useMediaQuery";
import lightTheme from "../themes/lightTheme";
import darkTheme from "../themes/darkTheme";
import { LOGIN_USER } from '../utils/mutations';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

import Auth from "../utils/auth";
import CreateAccount from './Dialogs/CreateAccount';

const lngs = {
  en: { nativeName: 'English' },
  es: { nativeName: 'Espanol' },
  de: { nativeName: 'Deutsch' }
};

export default function SignIn() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const [login, { error }] = useMutation(LOGIN_USER);
    const [formState, setFormState] = useState({ email: '', password: '' });
    const [openCreateAccount, setOpenCreateAccount] = useState(false);
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    // update state based on form input changes
    const handleChange = (event) => {
      const { name, value } = event.target;

      setFormState({
        ...formState,
        [name]: value,
      });
    };

    // Submit form
    const handleSubmit = async (event) => {
      event.preventDefault();

      try {
        const { data } = await login({
          variables: { ...formState },
        });

        Auth.login(data.login.token);

      } catch (err) {
        console.error('Error:',err.message);
      }

      // Clear form values
      setFormState({
        email: '',
        password: '',
      });
    };
  
    return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 20,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              <Trans i18nKey="loginPage.part1">

              </Trans>
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                sx={{
                  '&:focus' : {
                    backgroundColor: prefersDarkMode ? 'black' : 'white'
                  },
                  input: {
                    backgroundColor: prefersDarkMode ? darkTheme.palette.background.default : lightTheme.palette.background.default,
                    color: prefersDarkMode ? darkTheme.palette.primary.main : 'black'
                  },
                }}
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={formState.email}
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                sx={{
                  '&:focus' : {
                    backgroundColor: prefersDarkMode ? 'black' : 'white'
                  },
                  input: {
                    backgroundColor: prefersDarkMode ? darkTheme.palette.background.default : lightTheme.palette.background.default,
                    color: prefersDarkMode ? darkTheme.palette.primary.main : 'black'
                  }
                }}
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={formState.password}
                onChange={handleChange}
              />
              <FormControlLabel
                control={<Checkbox value="remember" sx={{color: theme.palette.primary.main, "&.Mui-checked": {
                    "&, & + .MuiFormControlLabel-label": {
                      color: theme.palette.primary.main
                    }
                  }}} />}
                label="Remember me"
              />
              <Button
                onClick={handleSubmit}
                href="/"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: theme.palette.primary.main }}
              >
                <Trans i18nKey="loginPage.part5">

                </Trans>
              </Button>
              <Stack direction={'row'}>
                <Button onClick={() => setOpenCreateAccount(true)}>
                  <Trans i18nKey="loginPage.part3">

                  </Trans>
                </Button>
                <Button>
                  <Trans i18nKey="loginPage.part4">

                  </Trans>
                </Button>
              </Stack>
            </Box>
          </Box>
          {openCreateAccount && (
            <CreateAccount 
              openCreateAccount={openCreateAccount}
              setOpenCreateAccount={setOpenCreateAccount}
            />
          )}
        </Container>
    );
  }