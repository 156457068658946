import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useQuery } from '@apollo/client';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useTheme } from "@mui/material/styles";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import AdminNavbar from "./adminNav";
import NavDrawer from "../../components/Drawer";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
};

const AdminChallenges = () => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    // Tabs
    const [tabValue, setTabValue] = useState(
        window.location.hash !== "" ? Number(window.location.hash.slice(1)) : 0
    );
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        window.location.hash = `#${newValue}`;
    };

    return (
        <Grid container>
            <Grid item>
                <NavDrawer />
            </Grid>
            <Grid item xs>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="fullWidth"
                >
                    <Tab label="The Journey" {...a11yProps(0)} />
                    <Tab label="Other Challenges" {...a11yProps(1)} />
                </Tabs>
            </Grid>
        </Grid>
    )
};

export default AdminChallenges; 