import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import lightTheme from "../../../themes/lightTheme";
import darkTheme from "../../../themes/darkTheme";
import { DataGrid, GridNoRowsOverlay, GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

// Components
import CreateUserWorkout from "../Dialogs/CreateUserWorkout";

const ClientWorkoutsTab = (props) => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const currentDate = new Date().toLocaleDateString('en-US');

    // State Variables
    const [rows, setRows] = useState([]);
    const [workoutCount, setWorkoutCount] = useState(null);
    const [selectedRowData, setSelectedRowData] = useState();
    const [openCreateWorkout, setOpenCreateWorkout] = useState(false);
    const [myClient, setMyClient] = useState(null);
    const [hasCheckedIn, setHasCheckedIn] = useState(false);
    const [hasWorkedOut, setHasWorkedOut] = useState(false);

    useEffect(() => {
        if (props.thisClient?.dailyOneSheets) {
            const todaySheet = props.thisClient.dailyOneSheets.filter(sheet => sheet.forDate === currentDate);
            if (todaySheet.length > 0) {
                setHasCheckedIn(true)
            } else {
                setHasCheckedIn(false);
            }
        }

        if (props.thisClient?.workouts) {
            const todayWorkout = props.thisClient.workouts.filter(workout => workout.completedAt === currentDate);
            if (todayWorkout.length > 0) {
                setHasWorkedOut(true);
            } else {
                setHasWorkedOut(false);
            }
        }
    }, []);

    const handleRowClick = (rowData) => {
        setSelectedRowData(rowData);
    };

    // DataGrid Columns
    const columns = [
        {field: 'id', headerName: 'ID', flex: 1},
        {field: 'name', headerName: 'Name', flex: 1},
        {field: 'completedAt', headerName: 'Completed', flex: 1},
        {field: 'instructor', headerName: 'Instructor', flex: 1},
        {field: 'exercises', headerName: 'Exercises', flex: 1},
        {field: 'type', headerName: 'Type', flex: 1},
        {field: 'scheduled', headerName: 'Scheduled', flex: 1},
        {field: 'createdAt', headerName: 'Created At', flex: 1},
        {field: 'athletes', headerName: 'Athletes', flex:1},
    ];

    useEffect(() => {
        if (props.thisClient) {
            setMyClient(props.thisClient);
            const workouts = props.thisClient.workouts;
            setWorkoutCount(workouts.length);
            const tmp = workouts.map((workout) => {
                return {
                    id: workout._id,
                    name: workout.name,
                    completedAt: workout.completedAt,
                    instructor: workout.instructor.fullName,
                    exercises: workout.exercises.length,
                    scheduled: workout.scheduled,
                    createdAt: workout.createdAt,
                    athletes: workout.athletes.length,
                    type: workout.athletes.length > 1 ? 'Group' : 'Individual'
                }
            })
            setRows(tmp);
        }
    }, [props.thisClient]);


    return (
        <>
            <Grid container direction={'column'} marginTop={'14px'}>
                <Grid item sx={{display:'flex', justifyContent:'space-around', marginTop:'20px'}}>
                    <Card sx={{width: '20%', height:'240px', backgroundColor: prefersDarkMode ? 'transparent' : 'white'}} elevation={3}>
                        <CardContent>
                            <Stack sx={{display:'flex', alignItems:'center'}}>
                                <h4 style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Total Workouts</h4>
                                <Typography variant="h2">{workoutCount}</Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Card sx={{width: '20%', height:'240px', backgroundColor: prefersDarkMode ? 'transparent' : 'white'}} elevation={3}>
                        <CardContent>
                            <Stack sx={{display:'flex', alignItems:'center'}}>
                                <h4 style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Today</h4>
                                <p>Checked-In {hasCheckedIn ? <CheckIcon /> : <CloseIcon />}</p>
                                <p>Worked out {hasWorkedOut ? <CheckIcon /> : <CloseIcon />}</p>
                            </Stack> 
                        </CardContent>
                    </Card>
                    <Card sx={{width: '20%', height:'240px', backgroundColor: prefersDarkMode ? 'transparent' : 'white'}} elevation={3}>
                        <CardContent>
                            <Stack sx={{display:'flex', alignItems:'center'}}>
                                <h4 style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Hammerhead Score</h4>
                            </Stack>
                        </CardContent>
                        <CardActions sx={{justifyContent:'center'}}>
                            <Button>View Score Breakdown</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
            <Grid container direction={'column'} sx={{display:'flex', alignItems:'center', marginTop:'24px'}}>
                <Grid item>
                    <h4 style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>All Workouts for {props.thisClient?.fullName}</h4>
                </Grid>
                <Grid item width={'90%'} sx={{display:'flex', justifyContent:'flex-end', marginBottom:'8px'}}>
                    <Button startIcon={<AddIcon />} onClick={() => setOpenCreateWorkout(true)} variant="outlined">Create Workout</Button>
                </Grid>
                <Grid item width={'90%'} sx={{marginBottom:'20px'}}>
                    <DataGrid 
                                columns={columns}
                                rows={rows}
                                onRowClick={handleRowClick}
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            id: false,
                                            createdAt: false,
                                            scheduled: false,
                                            athletes: false,
                                        }
                                    }
                                }}
                                sx={{"& .MuiDataGrid-row:hover": {
                                    backgroundColor: prefersDarkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main
                                }}}
                    />
                </Grid>
            </Grid>
            {openCreateWorkout && (
                <CreateUserWorkout 
                    openCreateWorkout={openCreateWorkout}
                    setOpenCreateWorkout={setOpenCreateWorkout}
                    myClient={myClient}
                />
            )}
        </>
    );
};

export default ClientWorkoutsTab;