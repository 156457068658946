import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from 'react-i18next';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useQuery } from '@apollo/client';
import { useMutation } from "@apollo/client";
import { QUERY_EXERCISES } from "../../../utils/queries";
import { CREATE_USER_WORKOUT } from "../../../utils/mutations";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { useTheme } from "@mui/material/styles";
import lightTheme from "../../../themes/lightTheme";
import darkTheme from "../../../themes/darkTheme";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import Auth from "../../../utils/auth";
import Add from "@mui/icons-material/Add";

const CreateUserWorkout = (props) => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const theme = useTheme();
    const [createUserWorkout] = useMutation(CREATE_USER_WORKOUT);
    const { error: exerciseError, data: exerciseData } = useQuery(QUERY_EXERCISES);
    const currentDate = new Date().toLocaleDateString('en-US');
    const myId = Auth.getId();

    // State Variables
    const [change, setChange] = useState(true);
    const [rows, setRows] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState();
    const [expandDialog, setExpandDialog] = useState(false);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [workoutName, setWorkoutName] = useState(null);
    const [scheduledFor, setScheduledFor] = useState(null);
    const [exerciseArray, setExerciseArray] = useState([]);
    const [athletesArray, setAthletesArray] = useState([props.myClient._id]);
    const [notes, setNotes] = useState(null);

    const handleRowClick = (rowData) => {
        setSelectedRowData(rowData);
    };

    // Function that adds an exercise to the exerciseArray based off of the exerciseId of the selected row
    const handleAddExercise = (exerciseId) => {
        // Update the state variable with the new exerciseId
        setExerciseArray((prevExercises) => [...prevExercises, exerciseId]);
    };

    // Function that removes an exercise from the exerciseArray based off of the exerciseId of the selected row
    const handleRemoveExercise = (exerciseId) => {
        setExerciseArray((prevExercises) => prevExercises.filter(id => id !== exerciseId));
    };

    const columns = [
        {field: 'id', headerName: 'ID', flex:1},
        {field: 'name', headerName: 'Name', flex:1},
        {field: 'muscleGroup', headerName: 'Muscle Group', flex: 1},
        {field: 'select', headerName: 'Select Exercise', flex:0.5,
            renderCell: (params) => (
                exerciseArray?.includes(params.id) ? (
                    <Button
                        startIcon={<DeleteIcon />}
                        onClick={() => handleRemoveExercise(params.id)}
                    >
                    </Button>
                ):(
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => handleAddExercise(params.id)}
                    >
                    </Button>
                )
            )
        },
    ];

    useEffect(() => {
        if (exerciseData) {
            const tmp = exerciseData.exercises.map((exercise) => {
                return {
                    id: exercise._id,
                    name: exercise.name,
                    muscleGroup: exercise.muscleGroup,
                }
            });
            setRows(tmp);
        }
    }, [exerciseData]);

    const handleScheduleWorkout = (newDate) => {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        const formattedDate = new Date(newDate.$d).toLocaleDateString('en-US', options);
        setScheduledFor(formattedDate);
    };

    const createWorkout = async () => {
        try {
            const { data } = await createUserWorkout({
                variables: {
                    name: workoutName,
                    createdAt: currentDate,
                    scheduled: scheduledFor,
                    instructor: myId,
                    isWod: false,
                    exercises: exerciseArray,
                    athletes: athletesArray,
                    notes: notes,
                },
            });
            console.log('Created Workout: ', data.createUserWorkout);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <Dialog
                open={props.openCreateWorkout}
                onClose={() => props.setOpenCreateWorkout(false)}
                maxWidth={expandDialog ? 'md' : 'xs'}
                fullWidth
                PaperProps={{
                    style: {backgroundColor: prefersDarkMode ? 'black' : 'white'}
                }}
            >
                <DialogTitle>Creating Workout for {props.myClient.fullName}</DialogTitle>
                <DialogContent>
                    <Grid container direction={expandDialog ? 'row' : 'column'}>
                        <Grid item width={expandDialog ? '50%' : '90%'}>
                            <h4 style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Workout Details</h4>
                            <Stack spacing={2} marginTop={'6px'}>
                            <TextField 
                                label="Workout Name"
                                value={workoutName || ""}
                                onChange={(e) => {
                                    setWorkoutName(e.target.value);
                                    if (e.target.value) {
                                        setChange(false)
                                    } else {
                                        setChange(true);
                                    }
                                }}
                            />
                            <Button disabled={!workoutName} variant="outlined" endIcon={<CalendarMonthIcon />} onClick={() => setOpenCalendar(true)}>
                                Schedule
                            </Button>
                            <Button disabled={!workoutName} onClick={() => setExpandDialog(!expandDialog)} variant="outlined">
                                Exercises
                            </Button>
                            <TextField 
                                label="Notes"
                                multiline
                                disabled={!workoutName}
                                value={notes || ""}
                                onChange={(e) => {
                                    setNotes(e.target.value);
                                }}
                            />
                            <Stack direction={'row'} sx={{display:'flex', justifyContent:'space-around'}}>
                                {scheduledFor != null ? (
                                    <h5>Scheduled: {scheduledFor}</h5>
                                ):(
                                    <></>
                                )}
                                {exerciseArray.length > 0 ? (
                                    <h5>Exercise Count: {exerciseArray.length}</h5>
                                ):(
                                    <></>
                                )}
                            </Stack>
                            </Stack>
                        </Grid>
                        {expandDialog ? (
                            <Grid item width={expandDialog ? '50%' : '0%'} sx={{display:'flex', justifyContent:'center'}}>
                                <Stack width={'95%'}>
                                    <h4 style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Pick Exercises</h4>
                                    <DataGrid 
                                        columns={columns}
                                        rows={rows}
                                        onRowClick={handleRowClick}
                                        initialState={{
                                            columns: {
                                                columnVisibilityModel: {
                                                    id: false,
                                                    muscleGroup: false,
                                                }
                                            }
                                        }}
                                        sx={{"& .MuiDataGrid-row:hover": {
                                            backgroundColor: prefersDarkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main
                                        }}}
                                    />
                                </Stack>
                            </Grid>
                        ):(
                            <></>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.setOpenCreateWorkout(false)}>Cancel</Button>
                    <Button disabled={!workoutName} onClick={() => {createWorkout(); props.setOpenCreateWorkout(false); window.location.reload();}}>Save Workout</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openCalendar}
                onClose={() => setOpenCalendar(false)}
            >
                <DialogTitle>Schedule Workout</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar
                         sx={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main, backgroundColor: prefersDarkMode ? darkTheme.palette.background.default : lightTheme.palette.background.default}} 
                         onChange={handleScheduleWorkout} 
                        />
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setScheduledFor(null); setOpenCalendar(false);}}>Cancel</Button>
                    <Button onClick={() => setOpenCalendar(false)}>Select Date</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CreateUserWorkout;