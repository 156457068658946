import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import POCalendar from "../../pages/fullcalendar/POC";

const Scheduler = () => {
    return (
        <Grid container direction={'columm'}>
            <Grid item sx={{width:'100%'}}>
                <POCalendar />
            </Grid>
        </Grid>
    )
};

export default Scheduler;