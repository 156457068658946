import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useQuery } from '@apollo/client';
import { QUERY_USERS } from "../../utils/queries";

// Components
import NavDrawer from "../../components/Drawer";
import AdminAddUser from "../../components/admin/AddUserDialog";
import AdminUserDetails from "../../components/admin/UserDetails";
import AllUsersTab from "./AllUsersTab";
import PendingUsersTab from "./PendingUsersTab";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
};

const AdminUsers = () => {
    const { error: usersError, data: usersData } = useQuery(QUERY_USERS);
    const isPending = usersData?.users.filter(user => !user.accessApproved);

    // Tabs
    const [tabValue, setTabValue] = useState(
        window.location.hash !== "" ? Number(window.location.hash.slice(1)) : 0
    );
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        window.location.hash = `#${newValue}`;
    };

    // State Variables
    const [openAddUser, setOpenAddUser] = useState(false);

    const pendingAlert = <span>Pending Users <PriorityHighIcon sx={{color:'red'}} /></span>
    
    
    return (
        <Grid container>
            <Grid item>
                <NavDrawer />
            </Grid>
            <Grid item xs>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="fullWidth"
                >
                    <Tab label="Active Users" {...a11yProps(0)} />
                    <Tab label={isPending?.length > 0 ? pendingAlert : 'Pending Users'} {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                    <AllUsersTab 
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <PendingUsersTab />
                </TabPanel>
            </Grid>
        </Grid>
    )
};

export default AdminUsers;