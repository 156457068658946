import React, { useState } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import lightTheme from "../../../themes/lightTheme";
import darkTheme from "../../../themes/darkTheme";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

const ClientCheckInTab = (props) => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    // State Variables
    const [rows, setRows] = useState([]);

    // DataGrid Columns
    const columns = [
        {field: 'id', headerName: 'ID', flex: 1},
        {field: 'forDate', headerName: 'Date', flex: 1},
        {field: 'dailyHabits', headerName: 'Responses', flex: 1,
            renderCell: (params) => (
                <Button variant="outlined">View</Button>
            )
        },
    ];

    useEffect(() => {
        if (props.thisClient?.dailyOneSheets) {
            const tmp = props.thisClient?.dailyOneSheets.map((sheet) => {
                return {
                    id: sheet._id,
                    forDate: sheet.forDate,
                    dailyHabits: sheet.dailyHabits,
                }
            })
            setRows(tmp);
        }
    }, [props.thisClient?.dailyOneSheets]);

    return (
        <Box sx={{display:'flex'}}>
            <Grid container direction={'column'} sx={{display:'flex', alignItems:'center'}}>
                <Grid item>
                    <h2 style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Overall Analysis for {props.thisClient?.fullName}</h2>
                    <Stack direction={'row'}>
                        <Card>

                        </Card>
                        <Card>

                        </Card>
                        <Card>

                        </Card>
                        <Card>
                            
                        </Card>
                        <Card></Card>
                        <Card></Card>
                        <Card></Card>
                        <Card></Card>
                    </Stack>
                </Grid>
                <Grid item sx={{width:'80%', marginTop:'20px'}}>
                    <h4 style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Check-Ins for {props.thisClient?.fullName}</h4>
                    <DataGrid 
                        columns={columns}
                        rows={rows}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                }
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ClientCheckInTab;