import React, { useState } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { Suspense } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { EDIT_USER } from "../../utils/mutations";
import { QUERY_ACTIVE_NO_DOS, QUERY_USERS } from "../../utils/queries";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { PieChart, pieArcClasses } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Avatar from '@mui/material/Avatar';
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SportsMartialArtsIcon from '@mui/icons-material/SportsMartialArts';
import '../styles.css';

import NavDrawer from "../../components/Drawer";
import LoadingScreen from "../../components/tools/LoadingScreen";
import POCalendar from "../fullcalendar/POC";

const AdminHome = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const theme = useTheme();
    const currentDate = new Date().toLocaleDateString('en-US');

    const {error: DOSError, data: DOSData} = useQuery(QUERY_ACTIVE_NO_DOS);
    const {error: usersError, data: usersData} = useQuery(QUERY_USERS);
    const allActiveUsers = usersData?.users;
    const activeUsers = usersData?.users.filter((user) => user.isActive).length;
    const activeTrainers = usersData?.users.filter((user) => user.isTrainer);
    const trainerLength = activeTrainers?.length;
    const filteredDosUsers = DOSData?.users.filter(user => !user.isTrainer && !user.dailyOneSheets.some(sheet => sheet.forDate === currentDate));
    const completedDosUsers = DOSData?.users.filter(user => !user.isTrainer && user.dailyOneSheets.some(sheet => sheet.forDate === currentDate));
    
    const [editUser] = useMutation(EDIT_USER);

    // State Variables
    const [rows, setRows] = useState([]);
    const [secondRows, setSecondRows] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState();
    const [selectedDosData, setSelectedDosData] = useState();
    const [openUserDialog, setOpenUserDialog] = useState(false);
    const [filterModel, setFilterModel] = useState({
        items: [],
    });
    const [pickTrainer, setPickTrainer] = useState(false);
    const [confirmAssignTrainer, setConfirmAssignTrainer] = useState(false);
    const [trainerId, setTrainerId] = useState(null);
    const [trainerName, setTrainerName] = useState(null);
    const [triggerScheduler, setTriggerScheduler] = useState(false);
    const [schedulerUser, setSchedulerUser] = useState("");
    const [massUsers, setMassUsers] = useState();

    // DataGrid Columns
    const columns = [
        {field: 'id', headerName: 'ID', flex: 1},
        {field: 'name', headerName: 'Name', flex: 1},
        {field: 'status', headerName: 'Status', flex: 1},
        {field: 'dos', headerName: 'Daily One Sheets', flex: 1},
        {field: 'trainer', headerName: 'Trained by', flex: 1},
        {field: 'isTrainer', headerName: 'isTrainer', flex: 1},
        {field: 'isActive', headerName: 'isActive', flex: 1},
        {field: 'isAdmin', headerName: 'isAdmin', flex: 1},
    ];

    // Specific User DataGrid Columns
    const secondColumns = [
        {field: 'id', headerName: 'ID', flex: 1},
        {field: 'forDate', headerName: 'Date', flex: 1},
        {field: 'dailyHabits', headerName: 'Responses', flex: 1,
            renderCell: (params) => (
             (<Button variant="outlined" sx={{'&:hover': {color:'white', borderColor:'white'}}}>View</Button>)
            )
        },
    ];

    const handleRowClick = (rowData) => {
        setSelectedRowData(rowData);
        setOpenUserDialog(true);
    };

    const handleDosClick = (dosData) => {
        setSelectedDosData(dosData);
    };

    // Map All Active Users Who Haven't Checked In Today
    useEffect(() => {
        if (DOSData) {
            const filteredUsers = DOSData?.users.filter(user => !user.isTrainer && !user.dailyOneSheets.some(sheet => sheet.forDate === currentDate));
            const tmp = filteredUsers?.map((user) => {
                return {
                    id: user._id,
                    name: user.fullName,
                    dos: user.dailyOneSheets,
                    trainer: user.onTeam?.fullName,
                    status: 'Missing Check In',
                    isTrainer: user.isTrainer,
                    isActive: user.isActive,
                    isAdmin: user.isAdmin,
                }
            })
            setRows(tmp);
        } else {
            return null;
        }
    }, [DOSData]);

    // Map Selected User's Previous DailyOneSheets
    useEffect(() => {
        if (selectedRowData) {
            const tmp = selectedRowData?.row.dos.map((sheet) => {
                return {
                    id: sheet._id,
                    forDate: sheet.forDate,
                    dailyHabits: sheet.dailyHabits,
                }
            })
            setSecondRows(tmp);
        } else {
            return null;
        }
    }, [selectedRowData]);

    const assignTrainer = () => {
        setSelectedDosData(null);
        setPickTrainer(true);
    };

    const assignTrainerAsync = async () => {
        try {
            const { data } = await editUser({
                variables: {
                    _id: selectedRowData.row.id,
                    onTeam: trainerId,
                },
            });
            console.log('Assigned trainer: ', data.editUser);
        } catch (err) {
            console.error(err);
        }
    };

    const handleSchedulerChange = (event) => {
        const selectedUserId = event.target.value;
        const selectedUser = usersData?.users.find(user => user._id === selectedUserId);
        setSchedulerUser(selectedUser);
    };

    console.log('schedulerUser', schedulerUser);

    return (
      <Box sx={{ display: "flex" }}>
        <NavDrawer />
        {triggerScheduler === false ? (
          <Suspense fallback={<LoadingScreen />}>
            <Grid container direction={"column"} sx={{ display: "flex" }}>
            <Grid item>
              <h2
                style={{
                  color: prefersDarkMode
                    ? darkTheme.palette.primary.main
                    : lightTheme.palette.primary.main,
                  textAlign: "center",
                  fontFamily: "Montserrat",
                }}
              >
                <Trans i18nKey="adminHome.part1"></Trans>
              </h2>
            </Grid>
            <Grid item sx={{ display: "flex", justifyContent: "center" }}>
              <Stack
                direction={"row"}
                width={"100%"}
                sx={{ display: "flex", justifyContent: "center" }}
                spacing={2}
              >
                <Box sx={{ width: "20%" }}>
                  <Card
                    sx={{
                      marginBottom: "10px",
                      height: "210px",
                      width: "100%",
                      backgroundColor: prefersDarkMode
                        ? "transparent"
                        : "white",
                    }}
                    elevation={3}
                  >
                    <CardContent>
                      <Stack sx={{ display: "flex", alignItems: "center" }}>
                        <h4
                          style={{
                            color: prefersDarkMode
                              ? darkTheme.palette.primary.main
                              : lightTheme.palette.primary.main,
                          }}
                        >
                          Active Users
                        </h4>
                        <Typography
                          sx={{
                            color: prefersDarkMode
                              ? darkTheme.palette.primary.main
                              : lightTheme.palette.primary.main,
                          }}
                          variant="h3"
                        >
                          {activeUsers}
                        </Typography>
                      </Stack>
                    </CardContent>
                    <CardActions sx={{ justifyContent: "center" }}>
                      <Button onClick={() => navigate(`/admin/users`)}>
                        View All Users
                      </Button>
                    </CardActions>
                  </Card>
                  <Card
                    sx={{
                      height: "210px",
                      width: "100%",
                      backgroundColor: prefersDarkMode
                        ? "transparent"
                        : "white",
                    }}
                    elevation={3}
                  >
                    <CardContent>
                      <Stack sx={{ display: "flex", alignItems: "center" }}>
                        <h4
                          style={{
                            color: prefersDarkMode
                              ? darkTheme.palette.primary.main
                              : lightTheme.palette.primary.main,
                          }}
                        >
                          Active Trainers
                        </h4>
                        <Typography
                          sx={{
                            color: prefersDarkMode
                              ? darkTheme.palette.primary.main
                              : lightTheme.palette.primary.main,
                          }}
                          variant="h3"
                        >
                          {trainerLength}
                        </Typography>
                      </Stack>
                    </CardContent>
                    <CardActions sx={{ justifyContent: "center" }}>
                      <Button onClick={() => navigate(`/admin/trainers`)}>
                        View All Trainers
                      </Button>
                    </CardActions>
                  </Card>
                </Box>
                <Card
                  sx={{
                    height: "300px",
                    width: "40%",
                    backgroundColor: prefersDarkMode ? "transparent" : "white",
                  }}
                  elevation={3}
                >
                  <CardContent>
                    <Stack sx={{ display: "flex", alignItems: "center" }}>
                      <h4
                        style={{
                          color: prefersDarkMode
                            ? darkTheme.palette.primary.main
                            : lightTheme.palette.primary.main,
                        }}
                      >
                        <Trans i18nKey="adminHome.part3"></Trans>
                      </h4>
                      <PieChart
                        series={[
                          {
                            data: [
                              {
                                id: 0,
                                value: completedDosUsers?.length,
                                label: `Completed`,
                                color: prefersDarkMode
                                  ? darkTheme.palette.primary.main
                                  : lightTheme.palette.primary.main,
                              },
                              {
                                id: 1,
                                value: filteredDosUsers?.length,
                                label: `Waiting`,
                                color: prefersDarkMode
                                  ? darkTheme.palette.secondary.main
                                  : lightTheme.palette.secondary.main,
                              },
                            ],
                          },
                        ]}
                        width={500}
                        height={200}
                        sx={{
                          [`& .${pieArcClasses.faded}`]: {
                            fill: "gray",
                          },
                          "--ChartsLegend-itemWidth": "0px",
                        }}
                      />
                    </Stack>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    height: "300px",
                    width: "30%",
                    backgroundColor: prefersDarkMode ? "transparent" : "white",
                  }}
                  elevation={3}
                >
                  <CardContent>
                    <Stack sx={{ display: "flex", alignItems: "center" }}>
                      <h4
                        style={{
                          color: prefersDarkMode
                            ? darkTheme.palette.primary.main
                            : lightTheme.palette.primary.main,
                        }}
                      >
                        <Trans i18nKey="adminHome.part4"></Trans>
                      </h4>
                      <BarChart
                        xAxis={[
                          {
                            scaleType: "band",
                            data: ["Pathfinder", "Voyager", "Trailblazer"],
                          },
                        ]}
                        series={[
                          {
                            data: [4, 3, 5],
                            color: prefersDarkMode
                              ? darkTheme.palette.primary.main
                              : lightTheme.palette.primary.main,
                          },
                        ]}
                        width={350}
                        height={200}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Stack>
            </Grid>
            <Grid item marginTop={"20px"} marginBottom={"20px"}>
              <Stack sx={{ display: "flex", alignItems: "center" }}>
                <h4
                  style={{
                    color: prefersDarkMode
                      ? darkTheme.palette.primary.main
                      : lightTheme.palette.primary.main,
                  }}
                >
                  <Trans i18nKey="adminHome.part2"></Trans>
                </h4>
                <DataGrid
                  sx={{
                    width: "90%",
                    "& .MuiDataGrid-row:hover": {
                      backgroundColor: prefersDarkMode
                        ? darkTheme.palette.secondary.main
                        : lightTheme.palette.secondary.main,
                      // color: "red"
                    },
                  }}
                  columns={columns}
                  rows={rows}
                  onRowClick={handleRowClick}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                        dos: false,
                        isTrainer: false,
                        isActive: false,
                        isAdmin: false,
                      },
                    },
                    filter: {
                      filterModel: {
                        items: [
                          {
                            field: "isAdmin",
                            operator: "equals",
                            value: "false",
                          },
                        ],
                      },
                    },
                  }}
                  slots={{ toolbar: GridToolbar }}
                />
              </Stack>
            </Grid>
          </Grid>
          <Button onClick={() => {setMassUsers(allActiveUsers); setTriggerScheduler(true);}} sx={{width:'40px', height:'40px', marginTop:'340px'}} startIcon={<ArrowForwardIosIcon />}></Button>
          </Suspense>
        ) : (
          <Suspense fallback={<LoadingScreen />}>
            <Grid container direction={"column"} sx={{ display: "flex" }}>
              <Grid item>
                <Stack direction={"row"} sx={{display:'flex', justifyContent:'space-between'}}>
                  <Button startIcon={<ArrowBackIosIcon />} sx={{width:'160px', height: '40px', marginTop:'24px', marginLeft:'10px'}} variant="outlined" onClick={() => setTriggerScheduler(false)}>Back to HQ</Button>
                  <h2 
                    style={{
                        color: prefersDarkMode
                          ? darkTheme.palette.primary.main
                          : lightTheme.palette.primary.main,
                        textAlign: "center",
                        fontFamily: "Montserrat",
                      }}
                  >
                    Scheduler {schedulerUser ? (
                      <>
                        ({schedulerUser.fullName})
                      </>
                    ):(
                      <>
                        (All Users)
                      </>
                    )}
                  </h2>
                  <Box sx={{display:'flex', marginTop:'10px'}}>
                {schedulerUser ? (
                        <Avatar sx={{marginTop:'14px'}} src={schedulerUser.pictureUrl}></Avatar>
                    ):(
                        <></>
                    )}
                  <FormControl sx={{width:'200px', margin:'6px'}}>
                    <InputLabel>User</InputLabel>
                    <Select
                        value={schedulerUser ? schedulerUser._id : ""}
                        label="User"
                        onChange={handleSchedulerChange}
                    >
                        <MenuItem value="" onClick={() => {setSchedulerUser(null); setMassUsers(allActiveUsers);}}>All Users</MenuItem>
                        <MenuItem value="" disabled>All Trainers</MenuItem>
                        {usersData?.users.map(user => (
                            <MenuItem key={user._id} value={user._id}>
                                {user.fullName}
                            </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  </Box>
                </Stack>
                <Box>
                <POCalendar
                    schedulerUser={schedulerUser}
                    massUsers={massUsers}
                />
                </Box>
              </Grid>
            </Grid>
          </Suspense>
        )}
        {openUserDialog && (
          <Dialog
            open={openUserDialog}
            onClose={() => {
              setOpenUserDialog(false);
              setPickTrainer(null);
            }}
            maxWidth="md"
            fullWidth
            PaperProps={{
              style: { backgroundColor: prefersDarkMode ? "black" : "white" },
            }}
          >
            <DialogTitle>Viewing {selectedRowData.row.name}</DialogTitle>
            <DialogContent>
              <Grid container direction={"row"}>
                <Grid item width={"50%"}>
                  <h4>{selectedRowData.row.name} hasn't checked in today.</h4>
                  {selectedRowData.row.trainer ? (
                    <h5
                      style={{
                        color: prefersDarkMode
                          ? darkTheme.palette.primary.main
                          : lightTheme.palette.primary.main,
                      }}
                    >
                      {selectedRowData.row.name} is being trained by{" "}
                      {selectedRowData.row.trainer}
                    </h5>
                  ) : (
                    <>
                      <Stack direction={"row"}>
                        <h5>No Trainer Assigned</h5>
                        <Button
                          onClick={assignTrainer}
                          sx={{ marginLeft: "24px" }}
                          endIcon={<SportsMartialArtsIcon />}
                          variant="outlined"
                        >
                          Assign Trainer
                        </Button>
                      </Stack>
                    </>
                  )}
                  {selectedRowData.row.dos.length > 0 ? (
                    <>
                      <Stack direction={"row"}>
                        <h5
                          style={{
                            color: prefersDarkMode
                              ? darkTheme.palette.primary.main
                              : lightTheme.palette.primary.main,
                          }}
                        >
                          Previous Check-Ins
                        </h5>
                        <ArrowDownwardIcon
                          sx={{
                            marginTop: "20px",
                            marginLeft: "4px",
                            color: prefersDarkMode
                              ? darkTheme.palette.primary.main
                              : lightTheme.palette.primary.main,
                          }}
                        />
                      </Stack>
                      <DataGrid
                        disableColumnFilter
                        disableDensitySelector
                        disableColumnSelector
                        sx={{ width: "90%" }}
                        columns={secondColumns}
                        rows={secondRows}
                        onRowClick={handleDosClick}
                        initialState={{
                          columns: {
                            columnVisibilityModel: {
                              id: false,
                            },
                          },
                          filter: {
                            filterModel: {
                              items: [],
                            },
                          },
                        }}
                        slots={{
                          toolbar: GridToolbar,
                        }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true, // Gotta have that search bar!
                            quickFilterProps: { debounceMs: 500 },
                          },
                        }}
                        filterModel={filterModel}
                        onFilterModelChange={(model) => setFilterModel(model)}
                      />
                    </>
                  ) : (
                    <>
                      <h5 style={{ color: lightTheme.palette.secondary.main }}>
                        No Previous Check-Ins
                      </h5>
                    </>
                  )}
                </Grid>
                <Grid item width={"50%"}>
                  {selectedDosData ? (
                    <>
                      <h4
                        style={{
                          color: prefersDarkMode
                            ? darkTheme.palette.primary.main
                            : lightTheme.palette.primary.main,
                        }}
                      >
                        Showing Results for: {selectedDosData?.row.forDate}
                      </h4>
                      <ul
                        style={{
                          listStyleType: "none",
                          padding: 0,
                          color: prefersDarkMode
                            ? darkTheme.palette.primary.main
                            : lightTheme.palette.primary.main,
                        }}
                      >
                        {selectedDosData?.row.dailyHabits.map(
                          (habit, index) => (
                            <li key={index} style={{ marginTop: "10px" }}>
                              <strong>{habit.question}</strong>:<br></br>
                              <span
                                style={{
                                  color: prefersDarkMode ? "white" : "black",
                                }}
                              >
                                {habit.answer}
                              </span>
                            </li>
                          )
                        )}
                      </ul>
                    </>
                  ) : pickTrainer ? (
                    <>
                      <h4>Trainer List</h4>
                      <ul
                        style={{
                          listStyleType: "none",
                          padding: 0,
                          color: prefersDarkMode
                            ? darkTheme.palette.primary.main
                            : lightTheme.palette.primary.main,
                        }}
                      >
                        {activeTrainers.map((trainer, index) => (
                          <li key={index} style={{ marginTop: "10px" }}>
                            <Stack direction={"row"}>
                              <Button
                                onClick={() => {
                                  setConfirmAssignTrainer(true);
                                  setTrainerId(trainer._id);
                                  setTrainerName(trainer.fullName);
                                  console.log(trainer._id, trainer.fullName);
                                }}
                              >
                                {trainer.fullName}
                              </Button>
                              <Dialog
                                open={confirmAssignTrainer}
                                onClose={() => setConfirmAssignTrainer(false)}
                              >
                                <DialogContent>
                                  <p>
                                    Assign {trainerName} as a trainer to{" "}
                                    {selectedRowData.row.name}
                                  </p>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={() =>
                                      setConfirmAssignTrainer(false)
                                    }
                                  >
                                    Cancel
                                  </Button>
                                  <Button onClick={assignTrainerAsync}>
                                    Confirm
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </Stack>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenUserDialog(false);
                  setSelectedDosData(null);
                  setPickTrainer(null);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Box>
    );
};

export default AdminHome;