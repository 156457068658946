import React, { useState, useEffect } from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { QUERY_TRAINERS, QUERY_EXERCISES } from '../../utils/queries';
import { ADD_WORKOUT } from '../../utils/mutations';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
import Grid from "@mui/material/Grid";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "./styles.css";
import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export const StyleWrapper = styled.div`
  .fc-button.fc-prev-button, .fc-button.fc-next-button, .fc-button.fc-today-button,
  .fc-button.fc-button-primary, .fc-button.fc-button-primary.fc-button {
    background: #09aff0
  }, .fc-button.fc-prev-button {
    margin-left: 10px
  }
`

const POCalendar = (props) => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const theme = useTheme();
    const currentDate = new Date().toLocaleDateString('en-US');

    // GraphQL
    const { error: trainersError, data: trainersData } = useQuery(QUERY_TRAINERS);
    const { error: exerciseError, data: exerciseData } = useQuery(QUERY_EXERCISES);
    const trainers = trainersData ? trainersData?.getTrainers : [];
    const exercises = exerciseData ? exerciseData.exercises : [];
    const [addWorkout] = useMutation(ADD_WORKOUT);

    // State Variables
    const [change, setChange] = useState(true);
    const [rows, setRows] = useState([]);
    const [trainerRows, setTrainerRows] = useState([]);
    const [userRows, setUserRows] = useState([]);
    const [gridMode, setGridMode] = useState(null);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [openAddEvent, setOpenAddEvent] = useState(false);
    const [openAssignTrainer, setOpenAssignTrainer] = useState(false);
    const [openAddUsers, setOpenAddUsers] = useState(false);
    const [newEventType, setNewEventType] = useState(null);
    const [expandEvent, setExpandEvent] = useState(false);
    const [expandDialog, setExpandDialog] = useState(false);
    const [workoutName, setWorkoutName] = useState(null);
    const [workoutInstructor, setWorkoutInstructor] = useState(null);
    const [instructorName, setInstructorName] = useState(false);
    const [workoutDate, setWorkoutDate] = useState(null);
    const [exerciseArray, setExerciseArray] = useState([]);
    const [athletesArray, setAthletesArray] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState();
    const [notes, setNotes] = useState(null);

    // Assign Trainer Columns
    const columns = [
        {field: 'id', headerName: 'ID', flex:1},
        {field: 'name', headerName: 'Trainer', flex:1},
        {field: 'select', headerName: 'Select', flex:0.75,
            renderCell: (params) => (
                <Button
                    startIcon={<AddIcon />}
                    onClick={() => {setWorkoutInstructor(params.row.id); setInstructorName(params.row.name); setExpandDialog(false);}}
                >
                </Button>
            )
        },
    ];

    // Add Users to Workout Columns
    const userColumns = [
        {field: 'id', headerName: 'ID', flex: 1},
        {field: 'name', headerName: 'Name', flex:1},
        {field: 'select', headerName: 'Select', flex:0.75,
            renderCell: (params) => (
                <Button
                    startIcon={<AddIcon />}
                    onClick={() => {setAthletesArray(prevAthletes => [...prevAthletes, params.row.id]); console.log('athArr', athletesArray)}}
                ></Button>
            )
        },
    ];

    const handleRowClick = (rowData) => {
        setSelectedRowData(rowData);
    };

    // Function that adds a user to the athletesArray
    const handleAddUser = (userId) => {
        // Update the state variable with the new userId
        setAthletesArray((prevAthletes) => [...prevAthletes, userId]);
    };

    const handleRemoveUser = (userId) => {
        setAthletesArray((prevAthletes) => prevAthletes.filter(id => id !== userId));
    };

    const handleDateSelect = (selectInfo) => {
        console.log('selectInfo', selectInfo);
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = new Date(selectInfo.endStr).toLocaleDateString('en-US', options);
        console.log('formatThis', formattedDate);
        console.log('workoutDate', workoutDate);
        setWorkoutDate(formattedDate);
        setOpenAddEvent(true);
        // let title = prompt('Please enter a new title for your event');
        // let calendarApi = selectInfo.view.calendar;

        // calendarApi.unselect() 

        // if (title) {
        //     calendarApi.addEvent({
        //         id: 1,
        //         title,
        //         start: selectInfo.startStr,
        //         end: selectInfo.startStr,
        //         allDay: false,
        //     })
        // }
    };

    // Change Event Date
    const handleChangeWorkoutDate = (newDate) => {
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = new Date(newDate.$d).toLocaleDateString('en-US', options);
        setWorkoutDate(formattedDate);
    };

    // Create Workout in DB and Add to Scheduler
    const createWorkout = async () => {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        const preparedDate = new Date(workoutDate).toLocaleDateString('en-US', options);
        try {
            const { data } = await addWorkout({
                variables: {
                    name: workoutName,
                    scheduled: preparedDate,
                    instructor: workoutInstructor,
                    exercises: exerciseArray,
                    athletes: athletesArray,
                    notes: notes,
                },
            });
            console.log('Created Workout: ', data.addWorkout);
            window.location.reload();
        } catch (err) {
            console.error(err);
        }
    };

    const handleEventClick = (clickInfo) => {
        console.log('clickInfo', clickInfo);
        console.log('event check', clickInfo.event._def.extendedProps.name);
        setWorkoutName(clickInfo.event._def.extendedProps.name);
        setWorkoutInstructor(clickInfo.event._def.extendedProps.instructor);
        setWorkoutDate(clickInfo.event.startStr);
        setExpandEvent(true);
    };

    function formatDate(dateString) {
        // Split the date string into parts using "/"
        const parts = dateString.split('/');
        // Extract the month, day, and year parts
        const month = parts[0];
        const day = parts[1];
        const year = parts[2];
        // Create a new Date object with the parts and format it to 'YYYY-MM-DD'
        const formattedDate = new Date(`${year}-${month}-${day}`).toISOString().slice(0, 10);
        return formattedDate;
    };

    useEffect(() => {
        if (props.schedulerUser) {
            setAthletesArray([props.schedulerUser._id]);
            console.log('testingtesting', props.schedulerUser);
            const tmp = props.schedulerUser?.workouts.map((workout) => {
                return {
                    title: `${workout.instructor.fullName} with ${props.schedulerUser.fullName}`,
                    date: formatDate(workout.scheduled),
                    name: workout.name,
                    instructor: workout.instructor.fullName,
                }
            });
            setRows(tmp);
            
        } else if (props.massUsers || props.schedulerUser) {
            console.log('massUsers', props.massUsers);
            const allWorkouts = [];
            // Iterate over each user
            props.massUsers.forEach(user => {
                // Iterate over each workout of the user
                user.workouts.forEach(workout => {
                    // Check if the workout is not already in the allWorkouts array
                    if (!allWorkouts.some(existingWorkout => existingWorkout._id === workout._id)) {
                        // If it's not, add it to the allWorkouts array
                        allWorkouts.push(workout);
                    }
                });
            });
            const tmp2 = allWorkouts.map((twerkout) => {
                return {
                    title: twerkout.athletes.length > 1 ? `${twerkout.instructor.fullName} with Group` : `${twerkout.instructor.fullName} with ${twerkout.athletes[0].fullName}`,
                    date: formatDate(twerkout.scheduled),
                    name: twerkout.name,
                    instructor: twerkout.instructor.fullName,
                    athletes: twerkout.athletes,
                }
            });
            console.log('tmp2', tmp2);
            setRows(tmp2);
            // Make User's available to add to workout events
            const tmp3 = props.massUsers.map((user) => {
                return {
                    id: user._id,
                    name: user.fullName,
                }
            });
            setUserRows(tmp3);
        }
    }, [props.schedulerUser, props.massUsers]);

    // Hook to map trainer and exercise data
    useEffect(() => {
        if (trainers) {
            const tmp = trainers.map((trainer) => {
                return {
                    id: trainer._id,
                    name: trainer.fullName,
                }
            });
            setTrainerRows(tmp);
        }
        if (exercises) {
            const tmp2 = exercises.map((exercise) => {
                return {
                    id: exercise._id,
                    name: exercise.name,
                }
            });
        }
    }, [trainersData, exerciseData]);

    return (
        <>
            <StyleWrapper>
            <FullCalendar 
                plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
                initialView="dayGridWeek"
                headerToolbar={{
                    left: '',
                    center: 'title',
                    right: 'prev,next'
                }}
                events={rows}
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                select={handleDateSelect}
                eventClick={handleEventClick}
            />
            {expandEvent && (
                <Dialog
                    open={expandEvent}
                    onClose={() => {setWorkoutName(null); setWorkoutInstructor(null); setWorkoutDate(null); setExpandEvent(false);}}
                    maxWidth="sm"
                >
                    <DialogContent>
                        <TextField 
                            sx={{width:'240px'}}
                            label="Workout Name"
                            value={workoutName || ""}
                        />
                        <p>Client: {props.schedulerUser?.fullName}</p>
                        <p>Trainer: {workoutInstructor}</p>
                        <p>Date: {workoutDate}</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {setWorkoutName(null); setWorkoutInstructor(null); setWorkoutDate(null); setExpandEvent(false);}}>Close</Button>
                        <Button>Edit Workout</Button>
                    </DialogActions>
                </Dialog>
            )}
            {openAddEvent && (
                <Dialog
                    open={openAddEvent}
                    onClose={() => setOpenAddEvent(false)}
                    maxWidth={expandDialog ? 'md' : 'xs'}
                    fullWidth
                    PaperProps={{
                        style: {backgroundColor: prefersDarkMode ? 'black' : 'white'}
                    }}
                >
                    <DialogTitle>
                        {props.schedulerUser ? (
                            <>
                                New Event for {props.schedulerUser.fullName}
                            </>
                        ):(
                            <>
                                New Event
                            </>
                        )}
                    </DialogTitle>
                    <DialogContent>
                        {newEventType === null ? (
                            <Box sx={{display:'flex', justifyContent:'center'}}>
                                <FormControl sx={{width:'400px', marginTop:'10px'}}>
                                    <InputLabel>Event Type</InputLabel>
                                    <Select
                                        value={newEventType || ""}
                                        label="New Event"
                                    >
                                        <MenuItem className="mont-alt" onClick={() => setNewEventType('Workout')}>New Workout</MenuItem>
                                        <MenuItem className="mont-alt" onClick={() => setNewEventType('Video')}>Video Call</MenuItem>
                                        <MenuItem className="mont-alt" onClick={() => setNewEventType('Scan')}>In-Body Scan</MenuItem>
                                        <MenuItem className="mont-alt" onClick={() => setNewEventType('Other')}>Other</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        ):(
                            <></>
                        )}
                        {newEventType === 'Workout' ? (
                            <Grid container direction={expandDialog ? 'row' : 'column'}>
                                <Grid item width={expandDialog ? '50%' : '90%'}>
                                    <Stack spacing={2} marginTop={'6px'}>
                                        <TextField 
                                            label="Workout Name"
                                            value={workoutName || ""}
                                            onChange={(e) => {
                                                setWorkoutName(e.target.value);
                                                if (e.target.value) {
                                                    setChange(false);
                                                } else {
                                                    setChange(true);
                                                }
                                            }}
                                        />
                                        <Button disabled={!workoutName} onClick={() => setOpenCalendar(true)} sx={{height:'56px'}} variant="outlined">
                                            Schedule for {workoutDate}
                                        </Button>
                                        <Button onClick={() => {setGridMode('trainers'); setExpandDialog(!expandDialog);}} disabled={!workoutName} sx={{height:'56px'}} variant="outlined">
                                            {workoutInstructor === null ? 'Assign a Trainer' : `Trainer: ${instructorName}`}
                                        </Button>
                                        <Button disabled={!workoutName} onClick={() => {setGridMode('exercises'); setExpandDialog(!expandDialog);}} sx={{height:'56px'}} variant="outlined">
                                            Add Exercises
                                        </Button>
                                        <Button disabled={!workoutName} onClick={() => {setGridMode('users'); setExpandDialog(!expandDialog);}} sx={{height:'56px'}} variant="outlined">
                                            {props.schedulerUser ? (
                                                "Add More Users"
                                            ):(
                                                "Add Users"
                                            )}
                                        </Button>
                                        <TextField 
                                            label="Notes"
                                            multiline
                                            disabled={!workoutName}
                                            value={notes || ""}
                                            onChange={(e) => {
                                                setNotes(e.target.value);
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                                {expandDialog ? (
                                    <Grid item width={expandDialog ? '50%' : '0%'} sx={{display:'flex', justifyContent:'center'}}>
                                        <Stack width={'95%'}>
                                            <h4>
                                                {gridMode === 'exercises' ? 'Exercises' : gridMode === 'trainers' ? 'Trainers' : gridMode === 'users' ? 'Users' : ''}
                                            </h4>
                                            <DataGrid 
                                                columns={gridMode === 'trainers' ? columns : gridMode === 'users' ? userColumns : ''}
                                                rows={gridMode === 'trainers' ? trainerRows : gridMode === 'users' ? userRows : ''}
                                                initialState={{
                                                    columns: {
                                                        columnVisibilityModel: {
                                                            id: false,
                                                        }
                                                    }
                                                }}
                                            />
                                        </Stack>
                                    </Grid>
                                ):(
                                    <></>
                                )}
                            </Grid>
                        ): newEventType === 'Video' ? (
                            <>
                                hello world video
                            </>
                        ): newEventType === 'Scan' ? (
                            <>
                                hello world scan
                            </>
                        ): newEventType === 'Other' ? (
                            <>
                                hello world other
                            </>
                        ):(
                            <></>
                        )}
                    </DialogContent>
                    <DialogActions>
                            {newEventType === null ? (
                                <Button onClick={() => {setNewEventType(null); setWorkoutName(null); setWorkoutDate(null); setWorkoutInstructor(null); setOpenAddEvent(false); setNotes(null);}}>Close</Button>
                            ): newEventType === 'Workout' ? (
                                <>
                                    <Button onClick={() => {setNewEventType(null); setWorkoutName(null); setWorkoutDate(null); setWorkoutInstructor(null); setOpenAddEvent(false); setNotes(null);}}>Cancel</Button>
                                    <Button onClick={createWorkout} disabled={!workoutName}>Save Workout</Button>
                                </>
                            ): newEventType === 'Video' ? (
                                <>
                                    <Button onClick={() => {setNewEventType(null); setOpenAddEvent(false);}}>Cancel</Button>
                                    <Button>Schedule Meeting</Button>
                                </>
                            ): newEventType === 'Scan' ? (
                                <>
                                    <Button onClick={() => {setNewEventType(null); setOpenAddEvent(false);}}>Cancel</Button>
                                    <Button>Schedule Scan</Button>
                                </>
                            ): newEventType === 'Other' ? (
                                <>
                                    <Button onClick={() => {setNewEventType(null); setOpenAddEvent(false);}}>Cancel</Button>
                                    <Button>Schedule</Button>
                                </>
                            ):(
                                <></>
                            )}
                    </DialogActions>
                </Dialog>
            )}
            {openCalendar && (
                <Dialog
                    open={openCalendar}
                    onClose={() => setOpenCalendar(false)}
                >
                    <DialogTitle>Change Date</DialogTitle>
                    <DialogContent>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                            sx={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main, backgroundColor: prefersDarkMode ? darkTheme.palette.background.default : lightTheme.palette.background.default}} 
                            onChange={handleChangeWorkoutDate} 
                            />
                        </LocalizationProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button>Cancel</Button>
                        <Button>Select Date</Button>
                    </DialogActions>
                </Dialog>
            )}
            {openAssignTrainer && (
                <Dialog
                    open={openAssignTrainer}
                    onClose={() => setOpenAssignTrainer(false)}
                >
                    <DialogTitle>Choose a Trainer</DialogTitle>
                    <DialogContent>
                        <DataGrid 
                            columns={columns}
                            rows={trainerRows}
                            getRowId={(row) => row.id}
                            onRowClick={handleRowClick}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                },
                            }}
                        />
                    </DialogContent>
                </Dialog>
            )}
            {openAddUsers && (
                <Dialog
                    open={openAddUsers}
                    onClose={() => setOpenAddUsers(false)}
                >
                    <DialogTitle>Add Users to Workout</DialogTitle>
                    <DialogContent>
                        <DataGrid 
                            columns={userColumns}
                            rows={userRows}
                        />
                    </DialogContent>
                </Dialog>
            )}
            </StyleWrapper>
        </>
    )
};

export default POCalendar;

