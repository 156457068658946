import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import lightTheme from "../themes/lightTheme";
import darkTheme from "../themes/darkTheme";
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import { useQuery } from "@apollo/client";
import { QUERY_ME } from "../utils/queries";
import List from '@mui/material/List';
import { mainListItems, secondaryListItems } from '../components/listItems';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Divider from '@mui/material/Divider';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

<style>
@import url('https://fonts.googleapis.com/css2?family=Protest+Revolution&display=swap');
</style>

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const NavDrawer = (props) => {
    const [open, setOpen] = useState(true);
    const [openSettings, setOpenSettings] = useState(false);
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    const { error: meError, data: meData } = useQuery(QUERY_ME);
    const isAdmin = meData?.me.isAdmin;
    const isTrainer = meData?.me.isTrainer;
    const onTeam = meData?.me.onTeam;
    const pictureUrl = meData?.me.pictureUrl;

    const handleDrawerToggle = () => {
      setOpen((prevOpen) => !prevOpen)
    };

    return (
        <>
            <CssBaseline />
            <Drawer 
              variant='permanent' 
              open={open}
            >
            <Toolbar
                sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
                backgroundColor: prefersDarkMode ? darkTheme.palette.background.default : lightTheme.palette.background.default
                }}
            >
                <IconButton onClick={handleDrawerToggle}>
                {!open ? (
                    <ChevronRightIcon />
                ) : (
                    <ChevronLeftIcon />
                )}
                </IconButton>
            </Toolbar>
            <Divider sx={{backgroundColor: prefersDarkMode ? darkTheme.palette.background.default : lightTheme.palette.background.default}} />
            <List component="nav" sx={{backgroundColor: prefersDarkMode ? darkTheme.palette.background.default : lightTheme.palette.background.default, 
              color: prefersDarkMode ? 'white' : 'black'
            }}>
                {mainListItems(prefersDarkMode, openSettings, setOpenSettings, onTeam, pictureUrl, isAdmin, isTrainer)}
                <Divider sx={{ my: 1, backgroundColor: prefersDarkMode ? darkTheme.palette.background.default : lightTheme.palette.background.default }} />
                {secondaryListItems(prefersDarkMode, isAdmin, isTrainer, pictureUrl)}
            </List>
            </Drawer>
        </>
    )
};

export default NavDrawer;