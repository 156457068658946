import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useQuery } from "@apollo/client";
import { ADD_EXERCISE } from "../../utils/mutations";
import { useMutation } from "@apollo/client";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useTheme } from "@mui/material/styles";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const AdminAddExercise = (props) => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const theme = useTheme();
    const [addExercise] = useMutation(ADD_EXERCISE);

    // State Variables
    const [change, setChange] = useState(true);
    const [exerciseName, setExerciseName] = useState(null);
    const [muscleGroup, setMuscleGroup] = useState(null);

    const addNewExercise = async () => {
        setChange(false);
        try {
            const { data } = await addExercise({
                variables: {
                    name: exerciseName,
                    muscleGroup: muscleGroup,
                },
            });
            props.setOpenAddExercise(false);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Box>
            <Dialog
                open={props.openAddExercise}
                onClose={() => props.setOpenAddExercise(false)}
                PaperProps={{
                    style: {backgroundColor: prefersDarkMode ? 'black' : 'white'}
                }}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle sx={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Create New Exercise</DialogTitle>
                <DialogContent>
                    <Grid container direction={'row'} spacing={2}>
                        <Grid item width={'45%'}>
                            <Stack spacing={2}>
                                <TextField 
                                    id="name"
                                    label="Exercise Name"
                                    value={exerciseName || ""}
                                    onChange={(e) => {
                                        setExerciseName(e.target.value)
                                        setChange(false)
                                    }}
                                />
                                <TextField 
                                    id="muscleGroup"
                                    label="Muscle Group"
                                    value={muscleGroup || ""}
                                    onChange={(e) => {
                                        setMuscleGroup(e.target.value)
                                        setChange(false)
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item width={'45%'}>
                            
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.setOpenAddExercise(false)}>Cancel</Button>
                    <Button disabled={change} onClick={addNewExercise}>Save Exercise</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
};

export default AdminAddExercise;