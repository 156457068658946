import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useQuery } from "@apollo/client";
import { QUERY_ME } from "../utils/queries";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import lightTheme from "../themes/lightTheme";
import darkTheme from "../themes/darkTheme";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Typography } from "@mui/material";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import NavDrawer from "../components/Drawer";

const MyWorkouts = () => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const {error: meError, data: meData} = useQuery(QUERY_ME);

    // State Var
    const [rows, setRows] = useState([]);
    const [openCompleteDialog, setOpenCompleteDialog] = useState(false);
    const [change, setChange] = useState(true);

    const columns = [
        {field: 'id', headerName: 'ID', flex: 1},
        {field: 'name', headerName: 'Name', flex: 1},
        {field: 'scheduled', headerName: 'Scheduled', flex: 1},
        {field: 'completedAt', headerName: 'Completed', flex: 1,
            renderCell: (params) => {
                if (params.row.completedAt === null) {
                    return (
                        <Button onClick={() => setOpenCompleteDialog(true)} variant="outlined" endIcon={<PriorityHighIcon />}>Incomplete</Button>
                    )
                }
            }
        },
        {field: 'notes', headerName: 'Notes', flex: 1},
        {field: 'instructor', headerName: 'Trainer', flex: 1},
        {field: 'exercises', headerName: 'Exercises', flex: 1},
    ];

    useEffect(() => {
        if (meData?.me) {
            const tmp = meData?.me.workouts.map((workout) => {
                return {
                    id: workout._id,
                    name: workout.name,
                    scheduled: workout.scheduled,
                    completedAt: workout.completedAt,
                    notes: workout.notes,
                    instructor: workout.instructor._id,
                    exercises: workout.exercises,
                }
            });
            setRows(tmp);
        }
    }, [meData?.me]);

    const completeWorkout = async () => {

    };

    return (
        <Box sx={{display:'flex'}}>
            <NavDrawer />
            <Grid container direction={'column'} sx={{display: 'flex'}}>
                <Grid item marginTop={'20px'}>
                    <Stack direction={'row'} sx={{width:'100%', display:'flex', justifyContent:'space-around'}}>
                        <Card sx={{width:'20%', height:'225px', backgroundColor: prefersDarkMode ? 'transparent' : 'white'}}>
                            <CardContent>
                                <Stack>
                                    <h4>Total Workouts</h4>
                                    <Typography variant='h2'>
                                        {meData?.me.workouts.length}
                                    </Typography>
                                </Stack>
                            </CardContent>
                        </Card>
                        <Card sx={{width:'20%', height: '225px', backgroundColor: prefersDarkMode ? 'transparent' : 'white'}}>
                            <CardContent>

                            </CardContent>
                        </Card>
                        <Card sx={{width: '20%', height: '225px', backgroundColor: prefersDarkMode ? 'transparent' : 'white'}}>
                            <CardContent>

                            </CardContent>
                        </Card>
                    </Stack>
                </Grid>
                <Grid item>
                    <Stack sx={{display:'flex', alignItems:'center'}}>
                        <h4>My Workouts</h4>
                        <DataGrid 
                            columns={columns}
                            rows={rows}
                            sx={{width:'90%', "& .MuiDataGrid-row:hover": {
                                backgroundColor: prefersDarkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main
                                // color: "red"
                            }}}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    }
                                }
                            }}
                        />
                    </Stack>
                </Grid>
            </Grid>
            {openCompleteDialog && (
                <Dialog
                    open={openCompleteDialog}
                    onClose={() => setOpenCompleteDialog(false)}
                    maxWidth="xs"
                    fullWidth
                >
                    <DialogTitle>Complete Workout</DialogTitle>
                    <DialogContent>
                        <Stack spacing={2} sx={{display:'flex', justifyContent:'center'}}>
                            <Button variant="outlined">Complete for Today</Button>
                            <Button variant="outlined">Select a different date</Button>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button>Cancel</Button>
                        <Button>Complete Workout</Button>
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    );
};

export default MyWorkouts;