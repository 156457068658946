import React, { useState } from "react";
import { useEffect } from "react";
import Auth from "../utils/auth";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';

const PendingApprovalPage = () => {

    const logMeOut = event => {
        event.preventDefault();
        Auth.logout();
    };

    return (
        <Box>
            <Grid container direction={"column"}>
                <Grid item sx={{display:'flex', justifyContent:'center', marginTop: '200px'}}>
                    <h2>Access Restricted: Pending Trainer Approval</h2>
                </Grid>
                <Grid item sx={{display: 'flex', justifyContent:'center'}}>
                </Grid>
                <Grid item sx={{display:'flex', justifyContent:'center'}}>
                    <Button onClick={logMeOut} variant="outlined">Logout</Button>
                </Grid>
            </Grid>
        </Box>
    )
};

export default PendingApprovalPage;