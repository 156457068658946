import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { ADD_WORKOUT } from "../../utils/mutations";
import { QUERY_TRAINERS, QUERY_EXERCISES } from "../../utils/queries";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { useTheme } from "@mui/material/styles";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SportsMartialArtsIcon from '@mui/icons-material/SportsMartialArts';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const AdminAddWorkout = (props) => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const theme = useTheme();

    // State Variables
    const [change, setChange] = useState(true);
    const [newWorkoutName, setNewWorkoutName] = useState(null);
    const [newWorkoutCreatedDate, setNewWorkoutCreatedDate] = useState(null);
    const [newWorkoutTrainer, setNewWorkoutTrainer] = useState(null);
    const [newWorkoutScheduled, setNewWorkoutScheduled] = useState(null);
    const [theseExercises, setTheseExercises] = useState([]);
    const [openAssignTrainer, setOpenAssignTrainer] = useState(false);
    const [openAddExercises, setOpenAddExercises] = useState(false);
    const [trainerRows, setTrainerRows] = useState([]);
    const [exerciseRows, setExerciseRows] = useState([]);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState();

    // GraphQL Data Fetches
    const { error: trainersError, data: trainersData } = useQuery(QUERY_TRAINERS);
    const { error: exercisesError, data: exercisesData } = useQuery(QUERY_EXERCISES);
    const trainers = trainersData ? trainersData.getTrainers : [];
    const exercises = exercisesData ? exercisesData.exercises : [];

    const [addWorkout] = useMutation(ADD_WORKOUT);

    const handleRowClick = (rowData) => {
        setSelectedRowData(rowData);
    };

    const handleScheduleWorkout = (newDate) => {
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = new Date(newDate.$d).toLocaleDateString('en-US', options);
        setNewWorkoutScheduled(formattedDate);
    };

    // Function that adds an exercise to the theseExercises array based off of the exerciseId of the selected row
    const handleAddExercise = (selectedRowData) => {
        // Update the state variable with the new exerciseId
        setTheseExercises((prevExercises) => [...prevExercises, selectedRowData]);

    };

    // Function that removes an exercise from the theseExercises array based off of the exerciseId of the selected row
    const handleRemoveExercise = (selectedRowData) => {
        setTheseExercises((prevExercises) => prevExercises.filter(id => id !== selectedRowData));
    };

    const createWorkout = async () => {
        setChange(false);
        try {
            const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
            const { data } = await addWorkout({
                variables: {
                    name: newWorkoutName,
                    scheduled: newWorkoutScheduled,
                    instructor: newWorkoutTrainer,
                    exercises: theseExercises,
                },
            });
            console.log('Created Workout: ', data.addWorkout);
        } catch (err) {
            console.error(err);
        }
    };

    // Trainer DataGrid Columns
    const trainerColumns = [
        {field: 'id', headerName: 'ID', flex: 1},
        {field: 'fullName', headerName: 'Name', flex: 1},
        {field: 'select', headerName: 'Select Trainer', flex: 1,
            renderCell: (params) => (
                <Button 
                    startIcon={<AddIcon />}
                    onClick={() => {setNewWorkoutTrainer(params.row.id); setOpenAssignTrainer(false)}}
                >
                </Button>
            ) 
        },
    ];

    // Exercises DataGrid Columns
    const exerciseColumns = [
        {field: 'id', headerName: 'ID', flex: 1},
        {field: 'name', headerName: 'Exercise Name', flex: 1},
        {field: 'muscleGroup', headerName: 'Muscle Group', flex: 1},
        {field: 'selected', headerName: 'Select Exercise', flex: 1,
            renderCell: (params) => (
                theseExercises.includes(params.id) ? (
                    <Button
                        onClick={() => handleRemoveExercise(params.row.id)}
                        startIcon={<DeleteIcon />}
                    >
                    </Button>
                ) : (
                    <Button
                        onClick={() => handleAddExercise(params.row.id)}
                        startIcon={<AddIcon />}
                    >
                    </Button>
                )
            )
        },
    ];

    // Hook to map trainer and exercise data
    useEffect(() => {
        if (trainers) {
            const tmp = trainers.map((trainer) => {
                return {
                    id: trainer._id,
                    fullName: trainer.fullName
                }
            });
            setTrainerRows(tmp);
        }
        if (exercises) {
            const tmp2 = exercises.map((exercise) => {
                return {
                    id: exercise._id,
                    name: exercise.name,
                    muscleGroup: exercise.muscleGroup,
                }
            });
            setExerciseRows(tmp2);
        }
    }, [trainersData, exercisesData]);
    
    return (
        <Box>
            <Dialog 
            open={props.openAddWorkout} 
            onClose={() => props.setOpenAddWorkout(false)} 
            PaperProps={{
                style: {backgroundColor: prefersDarkMode ? 'black' : 'white'}
            }}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle sx={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Create New Workout</DialogTitle>
            <DialogContent>
                <Grid container direction={'row'} sx={{display:'flex', justifyContent:'center', marginTop:'10px'}} spacing={2}>
                    <Grid item width={'45%'}>
                        <Stack spacing={2}>
                            <TextField 
                                id="name"
                                label="Workout Name"
                                value={newWorkoutName || ""}
                                onChange={(e) => {
                                    setNewWorkoutName(e.target.value);
                                    if (e.target.value) {
                                        setChange(false);
                                    } else {
                                        setChange(true);
                                    }
                                }}
                            />
                            <Button onClick={() => setOpenCalendar(true)} endIcon={<CalendarMonthIcon />} variant="outlined" sx={{height:'56px'}} disabled={!newWorkoutName}>Schedule Workout</Button>
                        </Stack>
                    </Grid>
                    <Grid item width={'45%'}>
                        <Stack spacing={2}>
                            <Button endIcon={<SportsMartialArtsIcon />} variant="outlined" sx={{height:'56px'}} onClick={() => setOpenAssignTrainer(true)} disabled={!newWorkoutName}>Assign a Trainer</Button>
                            <Button endIcon={<FitnessCenterIcon />} variant="outlined" sx={{height:'56px'}} onClick={() => setOpenAddExercises(true)} disabled={!newWorkoutName}>Add Exercises</Button>
                        </Stack>
                    </Grid>
                </Grid>
                <Stack direction={'row'} sx={{display:'flex', justifyContent:'space-around'}}>
                    {newWorkoutScheduled ? (
                        <p style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Scheduled For: {newWorkoutScheduled}</p>
                    ): (
                        <></>
                    )}
                    {newWorkoutTrainer ? (
                        <p style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Trainer: {newWorkoutTrainer}</p>
                    ) : (
                        <></>
                    )}
                    {theseExercises.length > 0 ? (
                        <p style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Exercise Count: {theseExercises.length}</p>
                    ) : (
                        <></>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setOpenAddWorkout(false)}>Cancel</Button>
                <Button onClick={createWorkout}>Create Workout</Button>
            </DialogActions>
        </Dialog>
        {openAssignTrainer && (
            <Dialog 
                open={openAssignTrainer} 
                onClose={() => setOpenAssignTrainer(false)}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {backgroundColor: prefersDarkMode ? 'black' : 'white'}
                }}
            >
                <DialogTitle sx={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Choose a Trainer</DialogTitle>
                <DialogContent>
                    <DataGrid 
                        columns={trainerColumns}
                        rows={trainerRows}
                        getRowId={(row) => row.id}
                        onRowClick={handleRowClick}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                    />
                </DialogContent>
            </Dialog>
        )}
        {openCalendar && (
            <Dialog
                open={openCalendar}
                onClose={() => setOpenCalendar(false)}
                PaperProps={{
                    style: {backgroundColor: prefersDarkMode ? 'transparent' : 'white'}
                }}
            >
                <DialogTitle sx={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Schedule Workout</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar
                         sx={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main, backgroundColor: prefersDarkMode ? darkTheme.palette.background.default : lightTheme.palette.background.default}} 
                         onChange={handleScheduleWorkout} 
                        />
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setNewWorkoutScheduled(null); setOpenCalendar(false)}}>Cancel</Button>
                    <Button onClick={() => setOpenCalendar(false)}>Select Date</Button>
                </DialogActions>
            </Dialog>
        )}
        {openAddExercises && (
            <Dialog 
                open={openAddExercises} 
                onClose={() => setOpenAddExercises(false)}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {backgroundColor: prefersDarkMode ? 'black' : 'white'}
                }}
            >
                <DialogTitle sx={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Add Exercises</DialogTitle>
                <DialogContent>
                    <DataGrid 
                        columns={exerciseColumns}
                        rows={exerciseRows}
                        getRowId={(row) => row.id}
                        onRowClick={handleRowClick}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenAddExercises(false)}>Cancel</Button>
                    <Button>Save Exercises</Button>
                </DialogActions>
            </Dialog>
        )}
        </Box>
    )
};

export default AdminAddWorkout;