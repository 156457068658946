import React, { useState } from "react";
import { useEffect } from "react";
import { useQuery } from '@apollo/client';
import { QUERY_USERS } from "../../utils/queries";
import { useTheme } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AdminUserDetails from "../../components/admin/UserDetails";

const AllUsersTab = () => {
    const { error: usersError, data: usersData } = useQuery(QUERY_USERS);
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    // State Variables
    const [rows, setRows] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState();
    const [openUserDetails, setOpenUserDetails] = useState(false);

    // rowData is the data of the clicked DataGrid row
    const handleRowClick = (rowData) => {
        setSelectedRowData(rowData);
        setOpenUserDetails(true);
    };

    // DataGrid Columns
    const columns = [
        {field: 'id', headerName: 'ID', flex:1},
        {field: 'name', headerName: 'Name', flex:1},
        {field: 'isActive', headerName: 'Active', flex: 1,
            renderCell: (params) => {
                    if (params.row?.isActive) {
                        return <CheckIcon style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}} />
                    } else {
                        return <CloseIcon style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}} />
                    }
            }, 
        },
        {field: 'isTrainer', headerName: 'Trainer', flex: 1,
            renderCell: (params) => {
                if (params.row?.isTrainer) {
                    return <CheckIcon style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}} />
                } else {
                    return <CloseIcon style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}} />
                }
            },
        },
        {field: 'isAdmin', headerName: 'Admin', flex: 1,
            renderCell: (params) => {
                if (params.row?.isAdmin) {
                    return <CheckIcon style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}} />
                } else {
                    return <CloseIcon style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}} />
                }
            },
        },
        {field: 'moreDetails', headerName: 'Details', flex: 1,
            renderCell: (params) => {
                return (
                    <Button variant="outlined">View</Button>
                )
            }
        },
    ];

    useEffect(() => {
        if (usersData) {
            const tmp = usersData?.users.map((user) => {
                return {
                    id: user._id,
                    name: user.fullName,
                    email: user.email,
                    isActive: user.isActive,
                    isTrainer: user.isTrainer,
                    accessApproved: user.accessApproved,
                    isAdmin: user.isAdmin,
                    activeChallenge: user.activeChallenge,
                    calories: user.calories,
                    protein: user.protein,
                    carbohydrates: user.carbohydrates,
                    fats: user.fats,
                    gender: user.gender,
                    height: user.height,
                    weight: user.weight,
                    workouts: user.workouts,
                    dailyOneSheets: user.dailyOneSheets,
                }
            })
            setRows(tmp);
        } else {
            return null;
        }
    }, [usersData]);

    return (
        <Grid container direction={'column'}>
            <Grid item sx={{display:'flex', justifyContent:'center'}}>
                <Box sx={{marginTop:'20px', width:'85%'}}>
                <DataGrid
                    sx={{"& .MuiDataGrid-row:hover": {
                        backgroundColor: prefersDarkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main
                        // color: "red"
                      }}}
                    columns={columns}
                    rows={rows}
                    getRowId={(row) => row.id}
                    onRowClick={handleRowClick}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                                isActive: false,
                            },
                        },
                        filter: {
                            filterModel: {
                              items: [
                                {field: 'isActive', operator:"equals", value: "true"}
                              ],
                            },
                          },
                    }}
                    slots={{ toolbar: GridToolbar }}
                />
                </Box>
            </Grid>
            {openUserDetails && (
                <AdminUserDetails 
                    selectedRowData={selectedRowData}
                    openUserDetails={openUserDetails}
                    setOpenUserDetails={setOpenUserDetails}
                />
            )}
        </Grid>
    )
};

export default AllUsersTab;