import React, { useState } from "react";
import { useEffect } from "react";
import { useTheme } from "@emotion/react";
import { useQuery } from '@apollo/client';
import { useMutation } from "@apollo/client";
import { QUERY_USERS } from "../../utils/queries";
import { EDIT_USER } from "../../utils/mutations";
import useMediaQuery from "@mui/material/useMediaQuery";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const PendingUsersTab = () => {
    const { error: usersError, data: usersData } = useQuery(QUERY_USERS);
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const [editUser] = useMutation(EDIT_USER);

    // State Variables
    const [rows, setRows] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState();

    // rowData is the data of the clicked DataGrid row
    const handleRowClick = (rowData) => {
        setSelectedRowData(rowData);
    };

    const handleApproveUser = async () => {
        try {
            const { data } = await editUser({
                variables: {
                    name: selectedRowData?.row.fullName,
                    _id: selectedRowData?.row._id,
                    email: selectedRowData?.row.email,
                    accessApproved: true,
                    isActive: true,
                },
            });
            console.log('Approved User: ', data?.editUser);
        } catch (err) {
            console.error(err);
        }
    };

    // DataGrid Columns
    const columns = [
        {field: '_id', headerName: "ID", flex: 1},
        {field: 'fullName', headerName: 'Name', flex: 1},
        {field: 'email', headerName: 'Email', flex: 1},
        {field: 'accessApproved', headerName: 'Status', flex: 1,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    onClick={handleApproveUser}
                >
                    Approve User
                </Button>
            )
        },
    ];

    useEffect(() => {
        if (usersData) {
            const tmp = usersData?.users.filter(user => !user.accessApproved);
            tmp.map((user) => {
                return {
                    _id: user._id,
                    fullName: user.fullName,
                    email: user.email,
                    accessApproved: user.accessApproved,
                }
            })
            setRows(tmp);
        } else {
            return null;
        }
    }, [usersData]);

    return (
        <Grid container direction={'column'}>
            <Grid item sx={{display:'flex', justifyContent:'center'}}>
                <Box sx={{marginTop:'20px', width:'85%'}}>
                    <DataGrid
                        columns={columns}
                        rows={rows}
                        getRowId={(row) => row._id}
                        onRowClick={handleRowClick}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    _id: false,
                                },
                            },
                        }}
                        sx={{"& .MuiDataGrid-row:hover": {
                            backgroundColor: prefersDarkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main
                            // color: "red"
                          }}}
                    />
                </Box>
            </Grid>
        </Grid>
    )
};

export default PendingUsersTab;