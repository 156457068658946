import React, { useState } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useQuery } from '@apollo/client';
import { QUERY_TRAINERS } from "../../utils/queries";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useTheme } from "@mui/material/styles";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import '../styles.css';

import NavDrawer from "../../components/Drawer";

const AdminTrainers = () => {
    const { t, i18n } = useTranslation();
    const {error: trainersError, data: trainersData} = useQuery(QUERY_TRAINERS);
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    // State Variables
    const [rows, setRows] = useState([]);

    // DataGrid Columns
    const columns = [
        {field: 'id', headerName: 'ID', flex: 1},
        {field: 'fullName', headerName: 'Name', flex: 1},
        {},
        {},
    ];

    // Get All Trainers
    useEffect(() => {
        if (trainersData) {
            const tmp = trainersData.getTrainers.map((trainer) => {
                return {
                    id: trainer._id,
                    fullName: trainer.fullName
                }
            })
            setRows(tmp);
        } else {
            return null;
        }
    }, [trainersData]);

    return (
        <Box sx={{ display: 'flex' }}>
            <NavDrawer />
            <Grid container direction={'column'} sx={{display:'flex', alignItems:'center'}}>
                <Grid item>
                    <h2 style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main, fontFamily: 'Montserrat'}}>
                        <Trans i18nKey="adminTrainers.part1"></Trans>
                    </h2>
                </Grid>
                <Grid item width={'75%'}>
                    <DataGrid 
                        sx={{"& .MuiDataGrid-row:hover": {
                            backgroundColor: prefersDarkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main
                            // color: "red"
                          }}}
                        columns={columns}
                        rows={rows}
                        getRowId={(row) => row.id}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                        slots={{ toolbar: GridToolbar }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
};

export default AdminTrainers;