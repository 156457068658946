import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useMutation } from "@apollo/client";
import { EDIT_USER } from "../../utils/mutations";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import ArticleIcon from '@mui/icons-material/Article';
import BoltIcon from '@mui/icons-material/Bolt';
import GradingIcon from '@mui/icons-material/Grading';

const AdminUserDetails = (props) => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const navigate = useNavigate();
    const theme = useTheme();
    const firstName = props.selectedRowData.row.name.split(" ")[0];

    const [editUser] = useMutation(EDIT_USER);

    const [confirmMakeTrainer, setConfirmMakeTrainer] = useState(false);

    const makeTrainer = async () => {
        try {
            const { data } = await editUser({
                variables: {
                    _id: props.selectedRowData.row.id,
                    email: props.selectedRowData.row.email,
                    isTrainer: true,
                },
            });
            console.log('${firstName} is now a trainer: ', data.editUser);
            setConfirmMakeTrainer(false);
            props.setOpenUserDetails(false);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Box>
            <Dialog
                open={props.openUserDetails}
                onClose={() => props.setOpenUserDetails(false)}
                PaperProps={{
                    style: {backgroundColor: prefersDarkMode ? 'black' : 'white'}
                }}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle sx={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>User Details</DialogTitle>
                <DialogContent>
                    <Grid container direction={'row'}>
                        <Grid item width={'50%'}>
                            <h4 style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Profile Info</h4>
                            <Stack>
                                <p>Name: {props.selectedRowData.row.name}</p>
                                <p>Email: {props.selectedRowData.row.email}</p>
                                <p>Gender: {props.selectedRowData.row.gender}</p>
                                {props.selectedRowData.row.isActive ? (
                                    <p>Active: Yes</p>
                                ) : (
                                    <p>Active: No</p>
                                )}
                                {props.selectedRowData.row.isTrainer ? (
                                    <p>Trainer: Yes</p>
                                ) : (
                                    <p>Trainer: No</p>
                                )}
                                {props.selectedRowData.row.isAdmin ? (
                                    <p>Admin: Yes</p>
                                ) : (
                                    <p>Admin: No</p>
                                )}
                            </Stack>
                        </Grid>
                        <Grid item width={'50%'}>
                            <h4 style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Training Info</h4>
                            <Stack>
                                <p>Height (inches): {props.selectedRowData.row.height}</p>
                                <p>Weight (pounds): {props.selectedRowData.row.weight}</p>
                                <p>Calories: {props.selectedRowData.row.calories}</p>
                                <p>Protein (grams): {props.selectedRowData.row.protein}</p>
                                <p>Carbs (grams): {props.selectedRowData.row.carbohydrates}</p>
                                <p>Fats (grams): {props.selectedRowData.row.fats}</p>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Stack direction={'row'} sx={{display:'flex', justifyContent:'space-around', marginTop: '16px'}}>
                        <Button endIcon={<BoltIcon />} variant="outlined">Challenge</Button>
                        <Button endIcon={<ArticleIcon />} variant="outlined">Check Ins</Button>
                        <Button endIcon={<GradingIcon />} variant="outlined">Workouts</Button>
                        <Button onClick={() => navigate(`/admin/user/${props.selectedRowData.id}`)} variant="outlined">View Profile</Button>
                        {props.selectedRowData.row.isTrainer ? (
                            <Button variant="outlined">Trainer Details</Button>
                        ):(
                            <>
                                <Button onClick={() => setConfirmMakeTrainer(true)} sx={{color: '#de6a5d', borderColor: '#de6a5d', '&:hover': {borderColor: '#de6a5d', backgroundColor:'transparent'}}} variant="outlined">Make {firstName} a Trainer</Button>
                                <Dialog
                                    open={confirmMakeTrainer}
                                    onClose={() => setConfirmMakeTrainer(false)}
                                >
                                    <DialogContent>
                                        <p>Are you sure you want to make {props.selectedRowData.row.name} a trainer?<br></br>If so, {firstName} will have access to the Trainer Panel.</p>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setConfirmMakeTrainer(false)}>Cancel</Button>
                                        <Button onClick={makeTrainer}>Confirm</Button>
                                    </DialogActions>
                                </Dialog>
                            </>
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.setOpenUserDetails(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
};

export default AdminUserDetails;