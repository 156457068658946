import { gql } from '@apollo/client';

export const QUERY_USERS = gql`
    query users {
        users {
            _id
            email
            fullName
            isActive
            isAdmin
            isTrainer
            onTeam {
                _id
                fullName
            }
            accessApproved
            activeChallenge {
                _id
            }
            height
            weight
            gender
            pictureUrl
            calories
            protein
            carbohydrates
            fats
            workouts {
                _id
                name
                scheduled
                instructor {
                    _id
                    fullName
                }
                exercises {
                    _id
                    name
                }
                athletes {
                    _id
                    fullName
                }
            }
            dailyOneSheets {
                _id
                forDate
                dailyHabits {
                    question
                    answer
                }
            }
        }
    }
`;

export const QUERY_ACTIVE_NO_DOS = gql`
    query activeUsers {
        users {
            _id
            email
            fullName
            isActive
            isAdmin
            isTrainer
            onTeam {
                _id
                fullName
            }
            accessApproved
            dailyOneSheets {
                _id
                forDate
                dailyHabits {
                    question
                    answer
                }
            }
        }
    }
`;

export const GET_TEAM_MEMBERS = gql`
    query GetTeamMembers($trainerId: ID!) {
        getTeamMembers(trainerId: $trainerId) {
            _id
            email
            fullName
            isActive
            height
            weight
            gender
            calories
            protein
            carbohydrates
            fats
            accessApproved
            dailyOneSheets {
                _id
                forDate
                dailyHabits {
                    question
                    answer
                }
            }
            workouts {
                _id
                name
                createdAt
                completedAt
                scheduled
            }
        }
    }
`;

export const QUERY_USER = gql`
    query user($_id: ID) {
        user(_id: $_id) {
            _id
            email
            fullName
            isActive
            isAdmin
            onTeam {
                _id
                fullName
            }
            height
            weight
            gender
            pictureUrl
            calories
            protein
            carbohydrates
            fats
            dailyOneSheets {
                _id
                forDate
                dailyHabits {
                    question
                    answer
                }
            }
            workouts {
                _id
                name
                createdAt
                completedAt
                scheduled
                instructor {
                    _id
                    fullName
                }
                isWod
                exercises {
                    _id
                    name
                }
                athletes {
                    _id
                    fullName
                }
            }
        }
    }
`;

export const QUERY_TODAYS_DOS = gql`
    query todaysDailyOneSheet($user_id: ID, $forDate: String) {
        todaysDailyOneSheet(user_id: $user_id, forDate: $forDate) {
            _id
            user_id
            forDate
            dailyHabits {
                question
                answer
            }
        }
    }
`;

export const QUERY_ME = gql`
    {
        me {
            _id
            email
            fullName
            isActive
            isAdmin
            isTrainer
            onTeam {
                _id
                fullName
            }
            accessApproved
            height
            weight
            gender
            pictureUrl
            calories
            protein
            carbohydrates
            fats
            dailyOneSheets {
                _id
                user_id
                forDate
                dailyHabits {
                    question
                    answer
                }
            }
            workouts {
                _id
                name
                createdAt
                completedAt
                scheduled
                isWod
                notes
                instructor {
                    _id
                    fullName
                }
                exercises {
                    _id
                    name
                }
            }
        }
    }
`;

export const QUERY_TRAINERS = gql`
    query getTrainers {
        getTrainers {
            _id
            fullName
        }
    }
`;

export const QUERY_EXERCISE = gql`
    query exercises($id: ID!) {
        exercise(_id: $id) {
            _id
            name
            createdAt
            muscleGroup
            workout_id
        }
    }
`;

export const QUERY_EXERCISES = gql`
  query {
    exercises {
      _id
      name
      createdAt
      muscleGroup
      workout_id
    }
  }
`;

export const QUERY_WORKOUT = gql`
    query workouts($_id: ID!){
        workout(_id: $_id) {
            _id
            name
            createdAt
            completedAt
            scheduled
            instructor
            isWod
            exercises {
                _id
                name
                muscleGroup
                createdAt
            }
        }
    }
`;

// QUERY WORKOUT OF THE DAY
export const QUERY_WOD = gql`
    query {
        getWod {
            _id
            name
            createdAt
            completedAt
            scheduled
            instructor {
                _id
                fullName
            }
            isWod
            exercises {
                _id
            }
            athletes {
                _id
                fullName
            }
        }
    }
`;

export const QUERY_WORKOUTS = gql`
  query {
    workouts {
      _id
      name
      createdAt
      completedAt
      scheduled
      isWod
      instructor {
        _id
        fullName
        isActive
        isTrainer
        isAdmin
        gender
      }
      exercises {
        _id
        name
        muscleGroup
        createdAt
      }
      athletes {
        _id
        email
        fullName
        isActive
        isTrainer
        activeChallenge {
            _id
            name
            startDate
            endDate
            isActive
        }
        isAdmin
        height
        weight
        gender
        calories
        protein
        carbohydrates
        fats
      }
    }
  }
`;

// QUERY ALL CHALLENGES
export const QUERY_ALL_CHALLENGES = gql`
    query {
        challenges {
            _id
            name
            startDate
            endDate
            isActive
            trainer {
                _id
                fullName
                isActive
                activeChallenge
                onTeam
                isAdmin
                height
                weight
                gender
                calories
                protein
                carbohydrates
                fats
            }
            participants {
                _id
                fullName
                isActive
                activeChallenge
                onTeam
                height
                weight
                gender
                calories
                protein
                carbohydrates
                fats
            }
        }
    }
`;

export const QUERY_ALL_DOS = gql`
    query {
        adminDailyOneSheets {
            _id
            user_id
            forDate
            dailyHabits {
                question
                answer
            }
        }
    }
`;

// // QUERY ACTIVE CHALLENGES
// export const QUERY_ACTIVE_CHALLENGES = gql``;

// // QUERY A SINGLE CHALLENGE
// export const QUERY_CHALLENGE = gql``;


