import React, { useState } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { useMutation } from "@apollo/client";
import { S3_SIGNEDREQUEST } from "../utils/mutations";
import { QUERY_ME } from "../utils/queries";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import { TextField } from "@mui/material";
import Stack from '@mui/material/Stack';
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import KeyIcon from '@mui/icons-material/Key';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
import shark_main from "../static/shark_main.png";
import './styles.css';

import NavDrawer from "../components/Drawer";

const Profile = () => {
    const { t, i18n } = useTranslation();
    const { error, data} = useQuery(QUERY_ME);
    const thisUser = data?.me;
    console.log('thisUser', thisUser);

    // State Variables
    const [profilePicture, setProfilePicture] = useState(shark_main);
    const [newProfilePicture, setNewProfilePicture] = useState(false);
    const [openPicDialog, setOpenPicDialog] = useState(false);

    const [signS3] = useMutation(S3_SIGNEDREQUEST);

    // onFileChange
    const onFileChange = (e) => {
        setNewProfilePicture(true);
        setProfilePicture(e.target.files[0]);
        var previewImg = URL.createObjectURL(e.target.files[0]);
        var imagePreview = document.getElementById("preview-img");
        imagePreview.src = previewImg;
    };

    // Upload to S3 Function
    const uploadToS3 = async (file, signedRequest) => {
        const options = {
            headers: {
                "Content-Type": file.type
            }
        };
        await axios.put(signedRequest, file, options);
    };

    // Submit Function
    const submitFile = async (profilePicture) => {
        try {
            const response = await signS3({
                variables: {
                    filetype: profilePicture.type,
                    filename: "testpix",
                }
            });
    
            const { signedRequest, url } = response.data.signS3;
            console.log('signedRequest', signedRequest);
            console.log('url', url);
            await uploadToS3(profilePicture, signedRequest)
            
        } catch (err) {
            console.error(err);
        }
    };

    return (
      <Box sx={{ display: "flex" }}>
        <NavDrawer />
        <Grid container direction={"column"} sx={{ display: "flex" }}>
          <Grid item sx={{display:'flex', justifyContent:'center', width:'50%'}}>
            <Stack sx={{ marginTop: "50px", marginLeft: "50px" }}>
              <img
                src={thisUser?.pictureUrl}
                width={"200px"}
                height={"275px"}
                style={{ borderRadius: "6px" }}
              ></img>
              <p className="mont-alt">Name: {thisUser?.fullName}</p>
              <p className="mont-alt">Email: {thisUser?.email}</p>
              {thisUser?.height ? (
                <p className="mont-alt">Height: {thisUser?.height}</p>
              ) : (
                <></>
              )}
              {thisUser?.weight ? (
                <p className="mont-alt">Weight: {thisUser?.weight}</p>
              ) : (
                <></>
              )}
              <Button
                variant="outlined"
                sx={{ marginBottom: "10px" }}
                endIcon={<CloudUploadOutlinedIcon />}
                onClick={() => setOpenPicDialog(true)}
              >
                Update Profile Photo
              </Button>
              <Button variant="outlined" endIcon={<KeyIcon />}>
                <Trans i18nKey="profile.part1"></Trans>
              </Button>
            </Stack>
          </Grid>
        </Grid>
        {openPicDialog && (
          <Dialog
            open={openPicDialog}
            onClose={() => setOpenPicDialog(false)}
            maxWidth="xs"
            fullWidth
          >
            <DialogTitle>Select Profile Photo</DialogTitle>
            <DialogContent>
              <Stack sx={{ display: "flex", alignItems: "center" }}>
                <img
                  id="preview-img"
                  style={{
                    width: "140px",
                    height: "180px",
                    objectFit: "contain",
                    marginLeft: "10px",
                  }}
                />
                <Button
                  sx={{ marginTop: "8px" }}
                  variant="contained"
                  component="label"
                  endIcon={<CloudUploadOutlinedIcon />}
                >
                  Upload
                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg"
                    onChange={(e) => onFileChange(e)}
                    required
                    id="upload_file"
                  />
                </Button>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenPicDialog(false)}>Cancel</Button>
              <Button onClick={submitFile}>Update Photo</Button>
            </DialogActions>
          </Dialog>
        )}
      </Box>
    );
};

export default Profile;