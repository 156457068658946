import React, { useState } from "react";
import { useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const CheckInDetails = (props) => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const currentDate = new Date().toLocaleDateString('en-US');

    return (
        <Box>
            <Dialog
                open={props.openCheckInDetails}
                onClose={() => props.setOpenCheckInDetails(false)}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle sx={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Check-In Details for {currentDate}</DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.setOpenCheckInDetails(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
};

export default CheckInDetails;