import React from 'react';
import { Suspense } from 'react';
import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@emotion/react';
import CssBaseline from "@mui/material/CssBaseline";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    createHttpLink,
} from '@apollo/client';
// import protected routes
import { setContext } from '@apollo/client/link/context';
import useMediaQuery from "@mui/material/useMediaQuery";
import lightTheme from './themes/lightTheme';
import darkTheme from './themes/darkTheme';
import Button from '@mui/material/Button';
import "./main.css";
import Auth from "./utils/auth";

import Splash from './pages/Splash';
import LoadingScreen from './components/tools/LoadingScreen';

// Admin Pages
import AdminHome from './pages/admin/adminHome.js';
import AdminUsers from './pages/admin/adminUsers.js';
import AdminTrainers from './pages/admin/adminTrainers';
import AdminWorkouts from './pages/admin/adminWorkouts';
import AdminExercises from './pages/admin/adminExercises';
import AdminChallenges from './pages/admin/adminChallenges.js';
import AdminUserProfile from './pages/admin/adminUserProfile.js';
import Integrations from './pages/admin/adminIntegrations.js';

// Trainer Pages
import TrainerDashboard from './pages/trainer/TrainerDashboard';
import MyClients from './pages/trainer/MyClients';
import ClientProfile from './pages/trainer/ClientProfile';

// User Pages
import MyWorkouts from './pages/MyWorkouts';
import Profile from './pages/Profile';

const loggedIn = Auth.loggedIn();

const httpLink = createHttpLink({
    uri: "http://localhost:3001/graphql",
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("id_token");
  
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

function App() {
    const { t, i18n } = useTranslation();
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const theme = useTheme();

    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={prefersDarkMode ? darkTheme : lightTheme}>
            <CssBaseline />
            <Router>
                {Auth.loggedIn() ? (
                    <>
                        <Routes>
                    <Route 
                        path=""
                        element={<Splash 
                            prefersDarkMode={prefersDarkMode}
                            theme={theme}
                        />}
                    />
                    <Route 
                        path="/profile"
                        element={<Profile 
                            prefersDarkMode={prefersDarkMode}
                            theme={theme}
                        />}
                    />
                    <Route 
                        path="/myworkouts"
                        element={<MyWorkouts 
                            prefersDarkMode={prefersDarkMode}
                            theme={theme}
                        />}
                    />
                    <Route 
                        path="/admin/home"
                        element={<AdminHome 
                            prefersDarkMode={prefersDarkMode}
                            theme={theme}
                        />}
                    />
                    <Route 
                        path="/admin/users"
                        element={<AdminUsers 
                            prefersDarkMode={prefersDarkMode}
                            theme={theme}
                        />}
                    />
                    <Route 
                        path="/admin/user/:id"
                        element={<AdminUserProfile 
                            prefersDarkMode={prefersDarkMode}
                            theme={theme}
                        />}
                    />
                    <Route 
                        path="/admin/trainers"
                        element={<AdminTrainers 
                            prefersDarkMode={prefersDarkMode}
                            theme={theme}
                        />}
                    />
                    <Route 
                        path="/admin/workouts"
                        element={<AdminWorkouts 
                            prefersDarkMode={prefersDarkMode}
                            theme={theme}
                        />}
                    />
                    <Route 
                        path="/admin/exercises"
                        element={<AdminExercises 
                            prefersDarkMode={prefersDarkMode}
                            theme={theme}
                        />}
                    />
                    <Route 
                        path="/admin/challenges"
                        element={<AdminChallenges 
                            prefersDarkMode={prefersDarkMode}
                            theme={theme}
                        />}
                    />
                    <Route 
                        path="/admin/integrations"
                        element={<Integrations 
                            prefersDarkMode={prefersDarkMode}
                            theme={theme}
                        />}
                    />
                    <Route 
                        path="/trainer/dashboard"
                        element={<TrainerDashboard 
                            prefersDarkMode={prefersDarkMode}
                            theme={theme}
                        />}
                    />
                    <Route 
                        path="/trainer/clients"
                        element={<MyClients 
                            prefersDarkMode={prefersDarkMode}
                            theme={theme}
                        />}
                    />
                    <Route 
                        path="/trainer/client/:id"
                        element={<ClientProfile 
                            prefersDarkMode={prefersDarkMode}
                            theme={theme}
                        />}
                    />
                    <Route 
                        path="/loading-test"
                        element={<LoadingScreen />}
                        theme={theme}
                    />
                </Routes>
                    </>
                ):(
                    <>
                        <Routes>
                            <Route 
                                exact
                                path="*"
                                element={<Splash 
                                    prefersDarkMode={prefersDarkMode}
                                    theme={theme}
                                />}
                            />
                        </Routes>
                    </>
                )}
            </Router>
            {/* <footer className="footer">
                <div>
                  {Object.keys(lngs).map((lng) => (
                    <Button key={lng} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                      {lngs[lng].nativeName}
                    </Button>
                  ))}
                </div>
              </footer> */}
            </ThemeProvider>
        </ApolloProvider>
    );
};

export default function WrappedApp() {
    return (
        <Suspense fallback="...loading">
            <App />
        </Suspense>
    );
}
