import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import Auth from "../utils/auth";
import lightTheme from "../themes/lightTheme";
import darkTheme from "../themes/darkTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SportsMartialArtsIcon from '@mui/icons-material/SportsMartialArts';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import GradingIcon from '@mui/icons-material/Grading';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LanguageIcon from '@mui/icons-material/Language';
import ChecklistIcon from '@mui/icons-material/Checklist';
import BuildIcon from '@mui/icons-material/Build';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';

const logMeOut = event => {
    event.preventDefault();
    Auth.logout();
};

<style>
  @import url('https://fonts.googleapis.com/css2?family=Protest+Revolution&display=swap');
</style>

export const mainListItems = (prefersDarkMode, openSettings, setOpenSettings, onTeam, pictureUrl, isAdmin, isTrainer) => (
  <>
    <Link
      to="/"
      style={{
        textDecoration: "none",
        color: prefersDarkMode ? "white" : "black"
      }}
    >
      <ListItemButton>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <Typography style={{fontFamily:'Protest Revolution'}}>
          <ListItemText primary="Home" />
        </Typography>
      </ListItemButton>
    </Link>
    <Link
      to="/profile"
      style={{
        textDecoration: "none",
        color: prefersDarkMode ? "white" : "black",
      }}
    >
      <ListItemButton>
        <ListItemIcon>
          <AccountBoxIcon />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </ListItemButton>
    </Link>
    <Link
        to="/myworkouts"
        style={{
          textDecoration: "none",
          color: prefersDarkMode ? "white" : "black",
        }}
    >
      <ListItemButton>
        <ListItemIcon>
            <GradingIcon />
        </ListItemIcon>
        <ListItemText primary="My Workouts" />
      </ListItemButton>
    </Link>
    <Link
        to="/mytrainer"
        style={{
          textDecoration: "none",
          color: prefersDarkMode ? "white" : "black",
        }}
    >
      {onTeam ? (<>
        <ListItemButton>
        <ListItemIcon>
            <SportsMartialArtsIcon />
        </ListItemIcon>
        <ListItemText primary="My Trainer" />
      </ListItemButton>
      </>):(
        <></>
      )}
    </Link>
    <ListItemButton onClick={() => setOpenSettings(true)}>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Settings" />
    </ListItemButton>
    <ListItemButton onClick={logMeOut}>
      <ListItemIcon>
        <LogoutIcon onClick={logMeOut} />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItemButton>
    <Link to="/profile">
      {!isAdmin && !isTrainer ? (
        <ListItemButton sx={{display:'flex', justifyContent:'center'}}>
        <Avatar sx={{border: '0.5px solid', borderColor: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}} src={pictureUrl} />
      </ListItemButton>
      ):(
        <></>
      )}
    </Link>
    {openSettings && (
      <Dialog
        open={openSettings}
        onClose={() => setOpenSettings(false)}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          style: {backgroundColor: prefersDarkMode ? 'black' : 'white'}
      }}
      >
        <DialogTitle>Settings</DialogTitle>
        <DialogContent>
          <Grid container direction={"column"}>
            <Grid item sx={{ display: "flex", justifyContent: "center" }}>
              <IconButton sx={{ marginRight: "10px" }}>
                <LanguageIcon sx={{ width: "60px", height: "60px" }} />
              </IconButton>
              {prefersDarkMode ? (
                <IconButton sx={{ marginLeft: "10px" }}>
                  <LightModeIcon sx={{ width: "60px", height: "60px" }} />
                </IconButton>
              ) : (
                <IconButton>
                  <DarkModeIcon sx={{ width: "60px", height: "60px" }} />
                </IconButton>
              )}
              <IconButton sx={{ marginLeft: "10px" }}>
                <HelpIcon sx={{ width: "60px", height: "60px" }} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSettings(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    )}
  </>
);

export const secondaryListItems = (prefersDarkMode, isAdmin, isTrainer, pictureUrl) => (
      <>
          {isAdmin ? (<>
            <ListSubheader 
            component="div" 
            inset
            sx={{backgroundColor: prefersDarkMode ? 'black' : 'white'}}
            >
              Admin Panel
          </ListSubheader>
          <Link to="/admin/home" style={{textDecoration: "none", color: prefersDarkMode ? 'white' : 'black'}}>
            <ListItemButton>
                <ListItemIcon>
                    <DashboardIcon  />
                </ListItemIcon>
                <ListItemText primary="Headquarters" />
            </ListItemButton>
        </Link>
        <Link to="/admin/users" style={{textDecoration: "none", color: prefersDarkMode ? 'white' : 'black'}}>
            <ListItemButton>
                <ListItemIcon>
                    <PeopleAltIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
            </ListItemButton>
        </Link>
        <Link to="/admin/trainers" style={{textDecoration: "none", color: prefersDarkMode ? 'white' : 'black'}}>
            <ListItemButton>
                <ListItemIcon>
                    <SportsMartialArtsIcon />
                </ListItemIcon>
                <ListItemText primary="Trainers" />
            </ListItemButton>
        </Link>
        <Link to="/admin/workouts" style={{textDecoration: "none", color: prefersDarkMode ? 'white' : 'black'}}>
            <ListItemButton>
                <ListItemIcon>
                    <GradingIcon />
                </ListItemIcon>
                <ListItemText primary="Workouts" />
            </ListItemButton>
        </Link>
        <Link to="/admin/exercises" style={{textDecoration: "none", color: prefersDarkMode ? 'white' : 'black'}}>
            <ListItemButton>
                <ListItemIcon>
                    <FitnessCenterIcon />
                </ListItemIcon>
                <ListItemText primary="Exercises" />
            </ListItemButton>
        </Link>
        <Link to="/admin/challenges" style={{textDecoration: "none", color: prefersDarkMode ? 'white' : 'black'}}>
            <ListItemButton>
                <ListItemIcon>
                    <ReceiptLongIcon />
                </ListItemIcon>
                <ListItemText primary="Challenges" />
            </ListItemButton>
        </Link>
        <Link to="/admin/integrations" style={{textDecoration: "none", color: prefersDarkMode ? 'white' : 'black'}}>
            <ListItemButton>
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText primary="Integrations"></ListItemText>
            </ListItemButton>
        </Link>
        <Link to="/profile">
            <ListItemButton sx={{display:'flex', justifyContent:'center'}}>
              <Avatar sx={{border: '1px solid', borderColor: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}} src={pictureUrl} />
            </ListItemButton>
        </Link>
          </>): !isAdmin && isTrainer ? (<>
            <ListSubheader 
                component="div" 
                inset
                sx={{backgroundColor: prefersDarkMode ? 'black' : 'white'}}
            >
              Trainer Panel
            </ListSubheader>
            <Link to="/trainer/dashboard" style={{textDecoration: "none", color: prefersDarkMode ? 'white' : 'black'}}>
            <ListItemButton>
                <ListItemIcon>
                    <DashboardIcon  />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItemButton>
            </Link>
            <Link to="/trainer/clients" style={{textDecoration: "none", color: prefersDarkMode ? 'white' : 'black'}}>
            <ListItemButton>
                <ListItemIcon>
                    <PeopleAltIcon />
                </ListItemIcon>
                <ListItemText primary="My Clients" />
            </ListItemButton>
            </Link>
            <Link to="/trainer/checkin" style={{textDecoration: "none", color: prefersDarkMode ? 'white' : 'black'}}>
            <ListItemButton>
                <ListItemIcon>
                    <ChecklistIcon />
                </ListItemIcon>
                <ListItemText primary="Check Ins" />
            </ListItemButton>
            </Link>
            <Link to="/trainer/workouts" style={{textDecoration: "none", color: prefersDarkMode ? 'white' : 'black'}}>
            <ListItemButton>
                <ListItemIcon>
                    <GradingIcon />
                </ListItemIcon>
                <ListItemText primary="Workouts" />
            </ListItemButton>
            </Link>
            <Link to="/trainer/exercises" style={{textDecoration: "none", color: prefersDarkMode ? 'white' : 'black'}}>
            <ListItemButton>
                <ListItemIcon>
                    <FitnessCenterIcon />
                </ListItemIcon>
                <ListItemText primary="Exercises" />
            </ListItemButton>
            </Link>
            <Link to="/profile">
            <ListItemButton sx={{display:'flex', justifyContent:'center'}}>
              <Avatar sx={{border: '0.5px solid', borderColor: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}} src={pictureUrl} />
            </ListItemButton>
          </Link>
          </>) : (
            <></>
          )}
      </>
);