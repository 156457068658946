import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { QUERY_USER } from "../../utils/queries";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import NavDrawer from "../../components/Drawer";
import Auth from "../../utils/auth";

const AdminUserProfile = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const theme = useTheme();
    const currentDate = new Date().toLocaleDateString('en-US');
    let { id } = useParams();
    
    // State Variables
    const [myTeam, setMyTeam] = useState();

    const { data, error } = useQuery(QUERY_USER, {
        variables: { _id: id },
    });

    console.log('datauser', data?.user);

    return (
        <Box sx={{display:'flex'}}>
            <NavDrawer />
            
        </Box>
    );
};

export default AdminUserProfile;