import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
    mutation login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            token
            user {
                _id
                email
            }
        }
    }
`;

export const ADD_USER = gql`
    mutation addUser($email: String!, $password: String!, $fullName: String!, $accessApproved: Boolean, $isActive: Boolean, $isAdmin: Boolean, $height: String, $weight: String, $gender: String!, $calories: String, $protein: String, $carbohydrates: String, $fats: String, $isTrainer: Boolean, $activeChallenge: ID) {
        addUser(email: $email, password: $password, fullName: $fullName, accessApproved: $accessApproved, isActive: $isActive, isAdmin: $isAdmin, height: $height, weight: $weight, gender: $gender, calories: $calories, protein: $protein, carbohydrates: $carbohydrates, fats: $fats, isTrainer: $isTrainer, activeChallenge: $activeChallenge) {
            token
            user {
                _id
                email
                fullName
                isActive
                isAdmin
                isTrainer
                accessApproved
                height
                weight
                gender
                calories
                protein
                carbohydrates
                fats
            }
        }
    }
`;

export const EDIT_USER = gql`
  mutation editUser($_id: ID, $email: String, $fullName: String, $isActive: Boolean, $isAdmin: Boolean, $isTrainer: Boolean, $accessApproved: Boolean, $activeChallenge: ID, $height: String, $weight: String, $gender: String, $calories: String, $protein: String, $carbohydrates: String, $fats: String, $onTeam: ID) {
    editUser(_id: $_id, email: $email, fullName: $fullName, isActive: $isActive, isAdmin: $isAdmin, isTrainer: $isTrainer, accessApproved: $accessApproved, activeChallenge: $activeChallenge, height: $height, weight: $weight, gender: $gender, calories: $calories, protein: $protein, carbohydrates: $carbohydrates, fats: $fats, onTeam: $onTeam) {
        _id
        email
        fullName
        isActive
        isAdmin
        isTrainer
        accessApproved
        gender
        onTeam {
            _id
            fullName
        }
    }
  }  
`;

export const S3_SIGNEDREQUEST = gql`
  mutation($filename: String!, $filetype: String!){
    signS3(filename: $filename, filetype: $filetype){
        url
        signedRequest
    }
  }
`;

export const ADD_WORKOUT = gql`
    mutation addWorkout($name: String!, $scheduled: String, $createdAt: String, $completedAt: String, $isWod: Boolean, $exercises: [ID], $instructor: ID, $athletes: [ID]) {
        addWorkout(name: $name, scheduled: $scheduled, createdAt: $createdAt, completedAt: $completedAt, isWod: $isWod, exercises: $exercises, instructor: $instructor, athletes: $athletes) {
            _id
            name
            createdAt
            completedAt
            scheduled
            isWod
            instructor {
                _id
                fullName
                isActive
                isTrainer
                isAdmin
                gender
            }
            exercises {
                _id
                name
                createdAt
                muscleGroup
            }
            athletes {
                _id
                email
                fullName
                isActive
                isTrainer
                isAdmin
                height
                weight
                gender
                calories
                protein
                carbohydrates
                fats
            }
        }
    }
`;

export const CREATE_USER_WORKOUT = gql`
    mutation createUserWorkout($name: String!, $createdAt: String, $completedAt: String, $scheduled: String, $instructor: ID, $exercises: [ID], $athletes: [ID], $notes: String){
        createUserWorkout(name: $name, createdAt: $createdAt, completedAt: $completedAt, scheduled: $scheduled, instructor: $instructor, exercises: $exercises, athletes: $athletes, notes: $notes){
            _id
            name
            createdAt
            completedAt
            scheduled
            isWod
            notes
            instructor {
                _id
                fullName
                isActive
                isTrainer
                isAdmin
                gender
            }
            exercises {
                _id
                name
                createdAt
                muscleGroup
            }
            athletes {
                _id
                email
                fullName
                isActive
                isTrainer
                isAdmin
                height
                weight
                gender
                calories
                protein
                carbohydrates
                fats
            }
        }
    }
`;

export const ADD_CHALLENGE = gql`
    mutation addChallenge($name: String!, $startDate: String, $endDate: String, $isActive: Boolean, $trainer: ID, $participants: [ID]) {
        addChallenge(name: $name, startDate: $startDate, endDate: $endDate, isActive: $isActive, trainer: $trainer, participants: $participants) {
            name
            startDate
            endDate
            isActive
            trainer {
                _id
                fullName
                isActive
                activeChallenge
                onTeam
                isAdmin
                height
                weight
                gender
                calories
                protein
                carbohydrates
                fats
            }
            participants {
                _id
                fullName
                isActive
                activeChallenge
                onTeam
                height
                weight
                gender
                calories
                protein
                carbohydrates
                fats
            }
        }
    }
`;

export const ADD_EXERCISE = gql`
    mutation addExercise($name: String!, $createdAt: String, $muscleGroup: String) {
        addExercise(name: $name, createdAt: $createdAt, muscleGroup: $muscleGroup) {
            _id
            name
            createdAt
            muscleGroup
        }
    }
`;

export const CREATE_DOS = gql`
    mutation createDailyOneSheet($user_id: ID, $forDate: String, $dailyHabits: [DailyHabitInput]) {
        createDailyOneSheet(user_id: $user_id, forDate: $forDate, dailyHabits: $dailyHabits) {
            _id
            user_id
            forDate
            dailyHabits {
                question
                answer
            }
        }
    }
`;






