// lightTheme.js
import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#F2CD01',
    },
    secondary: {
        main: '#0C63F7',
    },
    background: {
        default: '#000000',
    },
    text: {
        primary: '#ffffff',
        secondary: '#F2CD01',
    },
    action: {
        active: '#0C63F7',
        hover: '#0C63F7',
        disabled: '#8a8888',
        disabledBackground: '#8a8888'
    },
  },
});

export default darkTheme;