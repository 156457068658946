import React, { useState } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useQuery } from '@apollo/client';
import { QUERY_EXERCISES } from "../../utils/queries";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useTheme } from "@mui/material/styles";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import '../styles.css';

import AdminNavbar from "./adminNav";
import NavDrawer from "../../components/Drawer";
import AdminAddExercise from "../../components/admin/AddExerciseDialog";


const AdminExercises = () => {
    const { t, i18n } = useTranslation();
    const { error, data } = useQuery(QUERY_EXERCISES);
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const theme = useTheme();

    // State Variables
    const [rows, setRows] = useState([]);
    const [openAddExercise, setOpenAddExercise] = useState(false);

    // DataGrid Columns
    const columns = [
        {field: 'id', headerName: 'ID', flex: 1},
        {field: 'name', headerName: 'Exercise Name', flex: 1},
        {field: 'muscleGroup', headerName: 'Muscle Group', flex: 1},
    ];

    // Get All Exercises
    useEffect(() => {
        if (data) {
            const tmp = data?.exercises.map((exercise) => {
                return {
                    id: exercise._id,
                    name: exercise.name,
                    muscleGroup: exercise.muscleGroup,
                }
            })
            setRows(tmp);
        } else {
            return null;
        }
    }, [data]);

    return (
        <Box sx={{display:'flex'}}>
            <NavDrawer />
            <Grid container direction={'column'} sx={{display:'flex', alignItems: 'center'}}>
                <Grid item>
                    <h2 style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main, fontFamily: 'Montserrat'}}>
                        <Trans i18nKey="adminExercises.part1"></Trans>
                    </h2>
                </Grid>
                <Grid item width={'75%'} sx={{display:'flex', justifyContent:'flex-end', marginBottom:'8px'}}>
                    <Button startIcon={<AddIcon />} onClick={() => setOpenAddExercise(true)} variant="outlined">
                        <Trans i18nKey="adminExercises.part2"></Trans>
                    </Button>
                </Grid>
                <Grid item width={'75%'}>
                    <DataGrid 
                        sx={{"& .MuiDataGrid-row:hover": {
                            backgroundColor: prefersDarkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main
                            // color: "red"
                          }}}
                        columns={columns}
                        rows={rows}
                        getRowId={(row) => row.id}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                        slots={{ toolbar: GridToolbar }}
                    />
                </Grid>
            </Grid>
            {openAddExercise && (
                <AdminAddExercise 
                    openAddExercise={openAddExercise}
                    setOpenAddExercise={setOpenAddExercise}
                />
            )}
        </Box>
    )
};

export default AdminExercises;