import React, { useState } from "react";
import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_DOS } from "../../utils/mutations";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useTheme } from "@mui/material/styles";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slider from '@mui/material/Slider';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

const DOSDialog = (props) => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const theme = useTheme();
    const [createDailyOneSheet] = useMutation(CREATE_DOS);
    const currentDate = new Date().toLocaleDateString('en-US');

    // State Variables
    const [change, setChange] = useState(true);
    const [sleepValue, setSleepValue] = useState(8);
    const [selectedFace, setSelectedFace] = useState(null);
    const [selectedCup, setSelectedCup] = useState(null);
    const [mentalHealth, setMentalHealth] = useState(null);
    const [waterConsumed, setWaterConsumed] = useState(null);
    const [nutritionResponse, setNutritionResponse] = useState('');
    const [stepResponse, setStepResponse] = useState('');
    const [nonExerciseResponse, setNonExerciseResponse] = useState('');

    // Handle Face Click
    const handleFaceClick = (buttonId, mentalHealthValue) => {
        setChange(false);
        setSelectedFace(buttonId === selectedFace ? null : buttonId);
        setMentalHealth(buttonId === selectedFace ? null : mentalHealthValue);
    };

    // Handle Water Click
    const handleWaterClick = (cupId, waterConsumedValue) => {
        setChange(false);
        setSelectedCup(cupId === selectedCup ? null : cupId);
        setWaterConsumed(cupId === selectedCup ? null : waterConsumedValue);
    };

    // Handle Sleep Slider
    const handleSleepSlider = (event, newValue) => {
        setChange(false);
        setSleepValue(newValue);
    };

    const createCheckIn = async () => {
        try {
            const { data } = await createDailyOneSheet({
                variables: {
                    user_id: props.userId,
                    forDate: currentDate,
                    dailyHabits: [
                        {
                            question: "What was your nutrition like yesterday?",
                            answer: nutritionResponse
                        },
                        {
                            question: "How much water did you drink yesterday?",
                            answer: waterConsumed
                        },
                        {
                            question: "How many hours of sleep did you get yesterday?",
                            answer: sleepValue.toString()
                        },
                        {
                            question: "How many steps did you walk yesterday?",
                            answer: stepResponse
                        },
                        {
                            question: "Was there any non-exercise activity you performed?",
                            answer: nonExerciseResponse
                        },
                        {
                            question: "How was your mental health yesterday?",
                            answer: mentalHealth
                        },
                    ],
                },
            });
            console.log('Check-In Complete', data.createDailyOneSheet);
            props.setOpenDailyOne(false);
            props.refreshParent();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Box>
            <Dialog 
                open={props.openDailyOne} 
                onClose={() => props.setOpenDailyOne(false)}
                maxWidth="lg"
                fullWidth
                PaperProps={{
                    style: {backgroundColor: prefersDarkMode ? 'black' : 'white'}
                }}
            >
                <DialogTitle sx={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>Daily One Sheet</DialogTitle>
                <DialogContent>
                    <Grid container direction={'row'}>
                        <Grid item width={'50%'}>
                            <Stack spacing={2} width={'90%'}>
                                <p>What was your nutrition like yesterday?</p>
                                <TextField 
                                    label="Nutrition"
                                    name="nutrition"
                                    value={nutritionResponse || ""}
                                    onChange={(e) => {
                                        setNutritionResponse(e.target.value);
                                    }}
                                    sx={{
                                        '& fieldset' : {
                                            borderColor: prefersDarkMode ? 'white' : 'grey'
                                        }
                                    }}
                                />
                                <p>How much water did you drink yesterday?</p>
                                <Stack direction={'row'} spacing={4}>
                                    <div>
                                        <Button 
                                            startIcon={<LocalDrinkIcon sx={{color: selectedCup != 1 ? 'gray' : prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}} />}
                                            onClick={() => handleWaterClick(1, '1oz - 32oz')}
                                        >
                                        </Button>
                                        <p>1oz - 32oz</p>
                                    </div>
                                    <div>
                                        <Button 
                                            startIcon={<LocalDrinkIcon sx={{color: selectedCup != 2 ? 'gray' : prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}} />}
                                            onClick={() => handleWaterClick(2, '33oz - 64oz')}
                                        >
                                        </Button>
                                        <p>33oz - 64oz</p>
                                    </div>
                                    <div>
                                        <Button 
                                            startIcon={<LocalDrinkIcon sx={{color: selectedCup != 3 ? 'gray' : prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}} />}
                                            onClick={() => handleWaterClick(3, '64oz - 99oz')}
                                        >
                                        </Button>
                                        <p>64oz - 99oz</p>
                                    </div>
                                    <div>
                                        <Button 
                                            startIcon={<LocalDrinkIcon sx={{color: selectedCup != 4 ? 'gray' : prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}} />}
                                            onClick={() => handleWaterClick(4, '100oz +')}
                                        >
                                        </Button>
                                        <p>100oz +</p>
                                    </div>
                                </Stack>
                                <p>How many hours of sleep did you get yesterday?</p>
                                <Stack spacing={1} sx={{display:'flex', alignItems:'center', color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}}>
                                <Slider 
                                        label="Sleep"
                                        size="small"
                                        onChange={handleSleepSlider}
                                        defaultValue={8}
                                        step={1}
                                        min={0}
                                        max={12}
                                        valueLabelDisplay="auto"
                                    />
                                    <h2>{sleepValue} hours</h2>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item width={'50%'}>
                            <Stack spacing={2} width={'90%'}>
                                <p>How many steps did you walk yesterday?</p>
                                <TextField 
                                    label="Steps"
                                    value={stepResponse || ""}
                                    onChange={(e) => {
                                        setStepResponse(e.target.value);
                                    }}
                                    sx={{
                                        '& fieldset' : {
                                            borderColor: prefersDarkMode ? 'white' : 'grey'
                                        }
                                    }}
                                />
                                <p>Was there any non-exercise activity you performed?</p>
                                <TextField 
                                    label="Other Activity"
                                    value={nonExerciseResponse || ""}
                                    onChange={(e) => {
                                        setNonExerciseResponse(e.target.value);
                                    }}
                                    sx={{
                                        '& fieldset' : {
                                            borderColor: prefersDarkMode ? 'white' : 'grey'
                                        }
                                    }}
                                />
                                <p>How was your mental health yesterday?</p>
                                <Stack direction={'row'} sx={{display:'flex', justifyContent:'center'}}>
                                    <Button onClick={() => handleFaceClick(1, 'Negative')} sx={{width: '60px', height: '60px'}} startIcon={<SentimentVeryDissatisfiedIcon sx={{width: '40px', height: '40px', color: selectedFace != 1 ? 'gray' : prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}} />}></Button>
                                    <Button onClick={() => handleFaceClick(2, 'Average')} sx={{width: '60px', height: '60px'}} startIcon={<SentimentNeutralIcon sx={{width: '40px', height: '40px', color: selectedFace != 2 ? 'gray' : prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}} />}></Button>
                                    <Button onClick={() => handleFaceClick(3, 'Positive')} sx={{width: '60px', height: '60px'}} startIcon={<SentimentVerySatisfiedIcon sx={{width: '40px', height: '40px', color: selectedFace != 3 ? 'gray' : prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main}} />}></Button>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.setOpenDailyOne(false)}>Cancel</Button>
                    <Button onClick={createCheckIn} disabled={change}>Submit to Trainer</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
};

export default DOSDialog;