import React, { useState } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useQuery } from '@apollo/client';
import { QUERY_WORKOUTS } from "../../utils/queries";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useTheme } from "@mui/material/styles";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import '../styles.css';

import AdminNavbar from "./adminNav";
import NavDrawer from "../../components/Drawer";
import AdminAddWorkout from "../../components/admin/AddWorkoutDialog";

const AdminWorkouts = () => {
    const { t, i18n } = useTranslation();
    const { error, data } = useQuery(QUERY_WORKOUTS);
    // const allWorkouts = data?.workouts;
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const theme = useTheme();

    // State Variables
    const [rows, setRows] = useState([]);
    const [openAddWorkout, setOpenAddWorkout] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState();

    // DataGrid Columns
    const columns = [
        {field: 'id', headerName: 'ID', flex: 1},
        {field: 'name', headerName: 'Name', flex: 1},
        {field: 'isWod', headerName: 'WOD', flex: 1},
        {field: 'scheduled', headerName: 'Scheduled', flex: 1},
        {field: 'instructor', headerName: 'Trainer', flex: 1},
        {},    
    ];

    const handleRowClick = (rowData) => {
        console.log('rowData', rowData);
    };

    // Map All Workouts
    useEffect(() => {
        if (data) {
            const tmp = data?.workouts.map((workout) => {
                return {
                    id: workout._id,
                    name: workout.name,
                    isWod: workout.isWod,
                    scheduled: workout.scheduled,
                    instructor: workout?.instructor?.fullName,
                }
            })
            setRows(tmp);
        } else {
            return null;
        }
    }, [data]);

    return (
        <Box sx={{display:'flex'}}>
            <NavDrawer />
            <Grid container direction={'column'} sx={{display:'flex', alignItems:'center'}}>
                <Grid item>
                    <h2 style={{color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main, fontFamily: 'Montserrat'}}>
                        <Trans i18nKey="adminWorkouts.part1"></Trans>
                    </h2>
                </Grid>
                <Grid item width={'90%'} sx={{display:'flex', justifyContent:'flex-end', marginBottom:'8px'}}>
                    <Button startIcon={<AddIcon />} variant="outlined" onClick={() => setOpenAddWorkout(true)}>
                        <Trans i18nKey="adminWorkouts.part2"></Trans>
                    </Button>
                </Grid>
                <Grid item width={'90%'}>
                    <DataGrid 
                        sx={{"& .MuiDataGrid-row:hover": {
                            backgroundColor: prefersDarkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main
                            // color: "red"
                          }}}
                        columns={columns}
                        rows={rows}
                        getRowId={(row) => row.id}
                        onRowClick={handleRowClick}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                        slots={{ toolbar: GridToolbar }}
                    />
                </Grid>
            </Grid>
            {openAddWorkout && (
                <AdminAddWorkout 
                    openAddWorkout={openAddWorkout}
                    setOpenAddWorkout={setOpenAddWorkout}
                />
            )}
        </Box>
    );
};

export default AdminWorkouts;