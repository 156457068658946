// lightTheme.js
import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#09aff0',
    },
    secondary: {
        main: '#d9d9d9',
    },
    background: {
        default: '#ffffff',
    },
    text: {
        primary: '#000000',
        secondary: '#09aff0',
    },
    action: {
        active: '#09aff0',
        hover: '#09aff0',
    },
  },
});

export default lightTheme;