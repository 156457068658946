import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_TEAM_MEMBERS } from "../../utils/queries";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import '../styles.css';

import NavDrawer from "../../components/Drawer";
import ClientProfile from "./ClientProfile";
import Auth from "../../utils/auth";

const thisId = Auth.getId();

const MyClients = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const theme = useTheme();
    const currentDate = new Date().toLocaleDateString('en-US');

    const { data, error } = useQuery(GET_TEAM_MEMBERS, {
        variables: { trainerId: thisId },
    });

    const myTeam = data?.getTeamMembers;

    // State Variables
    const [rows, setRows] = useState([]);

    const columns = [
        {field: 'id', headerName: 'ID', flex: 1},
        {field: 'name', headerName: 'Name', flex: 1},
        {field: 'status', headerName: 'Checked In Today', flex: 1},
        {field: 'challenge', headerName:'Challenge', flex: 1},
        {field: 'details', headerName: 'More Details', flex: 1,
            renderCell: (params) => {
                return (
                    <Button variant="outlined">View</Button>
                )
            }
        }
    ];

    const handleRowClick = (rowData) => {
        navigate(`/trainer/client/${rowData.id}`, rowData.id);
    };

    useEffect(() => {
        if (data?.getTeamMembers) {
            const tmp = data?.getTeamMembers.map((member) => {
                return {
                    id: member._id,
                    name: member.fullName,
                    challenge: "",
                    details: "",
                }
            })
            setRows(tmp);
        } else {
            return null;
        }
    }, [data?.getTeamMembers]);

    return (
        <Box sx={{display:'flex'}}>
            <NavDrawer />
            <Grid container direction={'column'} sx={{display:'flex'}}>
                <Grid item>
                    <h2 style={{ color: prefersDarkMode ? darkTheme.palette.primary.main : lightTheme.palette.primary.main, textAlign: 'center', fontFamily: 'Montserrat' }}>My Clients</h2>
                </Grid>
                <Grid item sx={{width:'100%'}}>
                    <Stack sx={{display:'flex', alignItems:'center', width:'100%'}}>
                    <DataGrid 
                        sx={{width:'90%', "& .MuiDataGrid-row:hover": {
                            backgroundColor: prefersDarkMode ? darkTheme.palette.secondary.main : lightTheme.palette.secondary.main
                            // color: "red"
                          }}}
                        columns={columns}
                        rows={rows}
                        onRowClick={handleRowClick}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                }
                            }
                        }}
                    />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MyClients;