import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { ADD_USER } from "../../utils/mutations";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Auth from "../../utils/auth";

const useStyles = makeStyles((theme) => ({
    blurredBackground: {
        backdropFilter: 'blur(20px)',
    },
}));

const CreateAccount = (props) => {
    const [change, setChange] = useState(true);
    const [createEmail, setCreateEmail] = useState(null);
    const [createFullName, setCreateFullName] = useState(null);
    const [createKey, setCreateKey] = useState(null);
    const [createGender, setCreateGender] = useState(null);
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const classes = useStyles();
    const navigate = useNavigate();
    const [addUser] = useMutation(ADD_USER);

    const handleUserSignup = async () => {
        setChange(false);
        try {
            const { data } = await addUser({
                variables: {
                    email: createEmail,
                    password: createKey,
                    fullName: createFullName,
                    gender: createGender,
                },
            });
            Auth.login(data.addUser.token);
            props.setOpenCreateAccount(false);
            navigate('/')
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div>
            <Dialog
                open={props.openCreateAccount}
                onClose={() => props.setOpenCreateAccount(false)}
                maxWidth="xs"
                fullWidth
                PaperProps={{
                    style: {backgroundColor: prefersDarkMode ? 'rgba(255, 255, 255, 0.07)' : 'white'}
                }}
                BackdropProps={{
                    classes: {
                      root: classes.blurredBackground,
                    },
                  }}
            >
                <DialogTitle>Create Account</DialogTitle>
                <DialogContent sx={{display:'flex', justifyContent:'center'}}>
                    <Stack spacing={2} direction={'column'} sx={{marginTop:'10px'}}>
                        <TextField 
                            sx={{width:'100%'}}
                            label="Email"
                            value={createEmail || ""}
                            onChange={(e) => {
                                setCreateEmail(e.target.value);
                                setChange(false);
                            }}
                        />
                        <TextField 
                            sx={{width: '100%'}}
                            label="Full Name"
                            value={createFullName || ""}
                            onChange={(e) => {
                                setCreateFullName(e.target.value);
                                setChange(false);
                            }}
                        />
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={createGender}
                                onChange={(e) => {
                                    setCreateGender(e.target.value);
                                }}
                            >
                                <FormControlLabel value="female" control={<Radio />} label="Female" />
                                <FormControlLabel value="male" control={<Radio />} label="Male" />
                            </RadioGroup>
                        </FormControl>
                        <TextField 
                            sx={{width: '100%'}}
                            label="Password"
                            type="password"
                            value={createKey || ""}
                            onChange={(e) => {
                                setCreateKey(e.target.value);
                                setChange(false);
                            }}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.setOpenCreateAccount(false)}>Cancel</Button>
                    <Button onClick={handleUserSignup} disabled={change}>Create Account</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};

export default CreateAccount;