import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from '@mui/material/Stack';
import shark_main from "../../../static/shark_main.png";
import '../../styles.css';

const ClientDetailsTab = (props) => {
    return (
            <Grid container direction={'row'}>
                <Grid item sx={{width:'50%', marginTop:'40px', marginLeft: '40px'}}>
                    <img src={props.thisClient?.pictureUrl || shark_main} style={{width:'250px', height:'300px', borderRadius:'6px'}}></img>
                    <Stack direction={'row'}>
                        <div style={{width: '50%'}}>
                            <p className="mont-alt">Name: {props.thisClient?.fullName}</p>
                            <p className="mont-alt">Email: {props.thisClient?.email}</p>
                            <p className="mont-alt">Gender: {props.thisClient?.gender || ""}</p>
                        </div>
                    </Stack>
                </Grid>
            </Grid>
    );
};

export default ClientDetailsTab;