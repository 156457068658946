import React, { useState } from "react";
import { Suspense } from 'react';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { QUERY_ME, QUERY_TODAYS_DOS } from "../utils/queries";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Auth from '../utils/auth';
import hh_logo from "../static/HH_logo.png";
import shark_main from "../static/shark_main.png";
import SignIn from "../components/Login";
import "./styles.css";

import PendingApprovalPage from "./PendingApproval";

import ProfileTabDetails from "../components/Tabs/ProfileTab";
import TodayTabDetails from "../components/Tabs/TodayTab";
import SchedulerTab from "../components/Tabs/SchedulerTab";
import HammerheadTabDetails from "../components/Tabs/HammerheadTab";
import NavDrawer from "../components/Drawer";

const lngs = {
  en: { nativeName: 'English' },
  es: { nativeName: 'Espanol' },
  de: { nativeName: 'Deutsch' }
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
};

const Splash = (props) => {
    const { t, i18n } = useTranslation();
    const currentDate = new Date().toLocaleDateString('en-US');

    // State Variables
    const [dailyOneComplete, setDailyOneComplete] = useState();
    const [todayCheckIn, setTodayCheckIn] = useState();

    const { error, data} = useQuery(QUERY_ME);
    const thisUser = data?.me;
    const admin = data?.me.isAdmin;
    const todayWorkout = data?.me.workouts.filter(workout => workout.scheduled === currentDate);
    
    useEffect(() => {
      if (data?.me) {
        const userDailyOneSheets = data?.me.dailyOneSheets;
        const currentDate = new Date().toLocaleDateString('en-US');
        // Filter dailyOneSheets that match the current date
        const todaysDOS = userDailyOneSheets?.filter(
          (dailyOneSheet) => dailyOneSheet.forDate === currentDate
        );

        if (todaysDOS.length > 0) {
          setDailyOneComplete(true);
          setTodayCheckIn(todaysDOS);
        } else {
          setDailyOneComplete(false);
        }
      }
    }, [data?.me]);

    // Tabs 
    const [tabValue, setTabValue] = useState(
        window.location.hash !== "" ? Number(window.location.hash.slice(1)) : 0
    );
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        window.location.hash = `#${newValue}`;
    };

    return (
          <Box>
          {!Auth.loggedIn() ? (
            <Suspense fallback="...loading">
              <SignIn />
            </Suspense>
          ) : data?.me.accessApproved ? (
            <Suspense fallback="...loading">
            <Grid container>
              <Grid item>
                <NavDrawer 
                  admin={admin}
                />
              </Grid>
              <Grid item xs>
              <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="fullWidth"
                >
                    <Tab label="Today" {...a11yProps(0)} />
                    <Tab label="The Journey" {...a11yProps(1)} />
                    <Tab label="Scheduler" {...a11yProps(2)} />
                </Tabs>
              <TabPanel value={tabValue} index={0}>
                <TodayTabDetails 
                    thisUser={thisUser}
                    todayWorkout={todayWorkout}
                    todayCheckIn={todayCheckIn}
                    dailyOneComplete={dailyOneComplete}
                    setDailyOneComplete={setDailyOneComplete}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <SchedulerTab />
              </TabPanel>
              </Grid>
            </Grid>
            </Suspense>
          ) : (
            <Suspense fallback="...loading">
              <PendingApprovalPage />
            </Suspense>
          )}
        </Box>
    );
};

export default Splash;